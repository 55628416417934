import { useDispatch, useSelector } from "react-redux";
import { globalErrorSelector, setGlobalError } from "../Slice/ShowGlobalErrorSlice";
import { Alert, Snackbar } from "@mui/material";

function ShowGlobalError() {

    const globalError = useSelector(globalErrorSelector);
    const dispatch = useDispatch();

    if (globalError === "") {
        return null;
    }

    function handleClose() {
        dispatch(setGlobalError(""));
    }

    return (
        <Snackbar open={globalError !== ""} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
            >
                <div dangerouslySetInnerHTML={{ __html: globalError }} />
            </Alert>
        </Snackbar>
    );
}

export default ShowGlobalError;