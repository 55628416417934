import {createSlice} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import {RootState} from "@/store/Store";
import {editCategoryThunk} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Thunks/editCategoryThunk";

export const notSelectedCategoryId = -1;
export const noParentCategoryId = "0";

function getInitialState() {
    return {
        categoryId: notSelectedCategoryId,
        categoryTitle: "",
        categoryParentId: noParentCategoryId,
        dialogIsOpened: false,
        saveEditCategoryRequestStatus: LoadingStatus.idle as LoadingStatus,
    };
}

export const KnowledgeBaseEditCategorySlice = createSlice({
    name: "KnowledgeBaseEditCategorySlice",
    initialState: getInitialState(),
    reducers: {
        setCategoryId: (state, action) => {
            state.categoryId = action.payload;
        },
        setCategoryTitle: (state, action) => {
            state.categoryTitle = action.payload;
        },
        setCategoryParentId: (state, action) => {
            state.categoryParentId = action.payload;
        },
        setDialogIsOpened: (state, action) => {
            state.dialogIsOpened = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(editCategoryThunk.pending, (state, action) => {
            state.saveEditCategoryRequestStatus = LoadingStatus.loading;
        })
        .addCase(editCategoryThunk.fulfilled, (state, action) => {
            state.saveEditCategoryRequestStatus = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success") {
                const initialState = getInitialState();
                state.categoryId = initialState.categoryId;
                state.categoryTitle = initialState.categoryTitle;
                state.categoryParentId = initialState.categoryParentId;
                state.dialogIsOpened = initialState.dialogIsOpened;
            }
        })
        .addCase(editCategoryThunk.rejected, (state, action) => {
            state.saveEditCategoryRequestStatus = LoadingStatus.failed;
        })
    },
});

export const {
    setCategoryId,
    setCategoryTitle,
    setCategoryParentId,
    setDialogIsOpened,
} = KnowledgeBaseEditCategorySlice.actions;

export const categoryIdSelector = (state: RootState) => state.inCpEditCategoryInKnowledgeBase.categoryId;
export const categoryTitleSelector = (state: RootState) => state.inCpEditCategoryInKnowledgeBase.categoryTitle;
export const categoryParentIdSelector = (state: RootState) => state.inCpEditCategoryInKnowledgeBase.categoryParentId;
export const dialogIsOpenSelector = (state: RootState) => state.inCpEditCategoryInKnowledgeBase.dialogIsOpened;
export const editCategoryStatusSelector = (state: RootState) => state.inCpEditCategoryInKnowledgeBase.saveEditCategoryRequestStatus;

export const KnowledgeBaseEditCategoryReducer = KnowledgeBaseEditCategorySlice.reducer;