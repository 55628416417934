import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "@/store/Store";
import {
    CreateArticleFormInterface
} from "@/features/InControlPanel/KnowledgeBase/CreateArticleForm/Interfaces/CreateArticleFormInterface";
import {saveArticleThunk} from "@/features/InControlPanel/KnowledgeBase/CreateArticleForm/Thunks/saveArticleThunk";
import {LoadingStatus} from "@/store/LoadingStatus";
import {
    CategoriesListItemInterface
} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Interfaces/CategoriesListItemInterface";
import {getThreeThunk} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Thunks/getThreeThunk";

const notSelectedId = -1;

function getInitialState() {
    return {
        formLoadingStatus: LoadingStatus.idle as LoadingStatus,
        previewLoadingStatus: LoadingStatus.idle as LoadingStatus,
        categoriesLoadingStatus: LoadingStatus.idle as LoadingStatus,
        categoriesList: [] as CategoriesListItemInterface[],

        form: {
            category: notSelectedId,
            title: "",
            previewFileName: "",
            shortDesc: "",
            fullText: "",
        } as CreateArticleFormInterface,

        validationErrors: {
        }
    } ;
}

export const CreateArticleFormSlice = createSlice({
    name: "CreateArticleFormSlice",
    initialState: getInitialState(),
    reducers: {
        setCategory: (state, action: PayloadAction<number>) => {
            state.form.category = action.payload;
        },
        setPreviewFileName: (state, action: PayloadAction<string>) => {
            state.form.previewFileName = action.payload;
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.form.title = action.payload;
        },
        setShortDesc: (state, action: PayloadAction<string>) => {
            state.form.shortDesc = action.payload;
        },
        setFullText: (state, action: PayloadAction<string>) => {
            state.form.fullText = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(saveArticleThunk.pending, (state, action) => {
            state.formLoadingStatus = LoadingStatus.loading;
        })
        .addCase(saveArticleThunk.fulfilled, (state, action) => {
            state.formLoadingStatus = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success") {
                const initialForm = getInitialState().form;
                state.form.category = initialForm.category;
                state.form.title = initialForm.title;
                state.form.previewFileName = initialForm.previewFileName;
                state.form.shortDesc = initialForm.shortDesc;
                state.form.fullText = initialForm.fullText;
            }
        })
        .addCase(saveArticleThunk.rejected, (state, action) => {
            state.formLoadingStatus = LoadingStatus.failed;
        })

        .addCase(getThreeThunk.pending, (state, action) => {
            state.categoriesLoadingStatus = LoadingStatus.loading;
        })
        .addCase(getThreeThunk.fulfilled, (state, action) => {
            state.categoriesLoadingStatus = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }
            if (action.payload.result === "success" && action.payload.data !== null) {
                state.categoriesList = action.payload.data.list;
            }
        })
        .addCase(getThreeThunk.rejected, (state, action) => {
            state.categoriesLoadingStatus = LoadingStatus.failed;
        })
    }
});

export const categorySelector = (state: RootState) => state.inCPCreateArticleForm.form.category;
export const titleSelector = (state: RootState) => state.inCPCreateArticleForm.form.title;
export const previewFileNameSelector = (state: RootState) => state.inCPCreateArticleForm.form.previewFileName;
export const shortDescSelector = (state: RootState) => state.inCPCreateArticleForm.form.shortDesc;
export const fullTextSelector = (state: RootState) => state.inCPCreateArticleForm.form.fullText;
export const articleIsReadyToSaveSelector = (state: RootState) => {
    return state.inCPCreateArticleForm.form.category !== notSelectedId &&
           state.inCPCreateArticleForm.form.title !== "" &&
        //    state.inCPCreateArticleForm.form.previewFileName !== "" &&
           state.inCPCreateArticleForm.form.shortDesc !== "" &&
           state.inCPCreateArticleForm.form.fullText !== "";
};
export const formLoadingStatusSelector = (state: RootState) => state.inCPCreateArticleForm.formLoadingStatus;
export const previewLoadingStatusSelector = (state: RootState) => state.inCPCreateArticleForm.previewLoadingStatus;
export const categoriesLoadingStatusSelector = (state: RootState) => state.inCPCreateArticleForm.categoriesLoadingStatus;
export const categoriesListSelector = (state: RootState) => state.inCPCreateArticleForm.categoriesList;
export const validationErrorsSelector = (state: RootState) => state.inCPCreateArticleForm.validationErrors;

export const {
    setCategory,
    setTitle,
    setShortDesc,
    setFullText,
    setPreviewFileName,
} = CreateArticleFormSlice.actions;

export const CreateArticleFormReducer = CreateArticleFormSlice.reducer;