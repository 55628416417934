import {ApiSlice} from "@/store/ApiSlice";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import {NewBaseResponseInterface} from "@/common/Http/Interfaces/NewBaseResponseInterface";
import { UserInterface } from "./Interfaces/UserInterface";

const restApiConfig = new RESTAPIConfig();

export const GetCurrentUserApiSlice = ApiSlice.injectEndpoints({
    endpoints: builder => ({
        getCurrentUser: builder.query<NewBaseResponseInterface<UserInterface>, void>({
            query: () => restApiConfig.currentUser(),
            providesTags: ["CurrentUser", "FetchedData"],
        }),
    }),
});

export const {useGetCurrentUserQuery} = GetCurrentUserApiSlice;