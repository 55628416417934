import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@/App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import reportWebVitals from './reportWebVitals';
import './i18n/i18n';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://7b980c9b8cdecc05db1287ff5f3ad7e7@o4507843307372544.ingest.de.sentry.io/4507843313074256",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      autoInject: false,
    }),
    // Sentry.debugIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/lk.inlab-genetics\.ru/, 
    /^https:\/\/b2b-inlab\.com\/api/,

    /^https:\/\/inlab\.dev/,
    /^https:\/\/test\.b2b-inlab\.com/,
    /^https:\/\/test\.lk\.inlab-genetics\.ru/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // enabled: false,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
