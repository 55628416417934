import { configureStore } from "@reduxjs/toolkit";
import {UILeftMenuReducer} from "@/components/Menu/LeftMenu/Slice/UILeftMenuSlice";
import {isDevMode} from "@/helpers/mode";
import {ApiSlice} from "@/store/ApiSlice";
import {RegisterNewPartnerFormReducer} from "@/features/InControlPanel/RegisterNewPartnerForm/Slice/RegisterNewPartnerFormSlice";
import {RegisterNewPartnerPhysicalAddressReducer} from "@/features/InControlPanel/RegisterNewPartnerForm/Slice/PhysicalAddressSlice";
import {ContactsReducer} from "@/features/InControlPanel/RegisterNewPartnerForm/Slice/ContactsSlice";
import {LoginFormReducer} from "@/features/Auth/LoginForm/Slice/LoginFormSlice";
import {LogoutReducer} from "@/features/Auth/CurrentUser/Slice/LogoutSlice";
import {OrderDNATestFormReducer} from "@/features/OrderDNATestForm/Slice/OrderDNATestFormSlice";
import {DNAOrdersFiltersAndSortersReducer} from "@/features/DNAOrders/Slice/DNAOrdersFiltersAndSortersSlice";
import {OpenedDNAOrderReducer} from "@/features/DNAOrderContent/Slice/OpenedDNAOrderSlice";
import {
    AllOrdersFiltersAndSortersReducer
} from "@/features/InControlPanel/AllOrdersToSeeByStaff/Slice/AllOrdersFiltersAndSortersSlice";
import {AllTestTypesReducer} from "@/features/InControlPanel/AllOrdersToSeeByStaff/Slice/AllTestTypesSlice";
import {InCPOpenedDNAOrderReducer} from "@/features/InControlPanel/DNAOrderContent/Slice/InCPOpenedDNAOrderSlice";
import {LabDocumentsUploadingReducer} from "@/features/InControlPanel/DNAOrderContent/Slice/LabDocumentsUploadingSlice";
import {
    InCPOpenedOrderLabFilesManipulationsReducer
} from "@/features/InControlPanel/DNAOrderContent/Slice/InCPOpenedOrderLabFilesManipulationsSlice";
import {CreateArticleFormReducer} from "@/features/InControlPanel/KnowledgeBase/CreateArticleForm/Slice/CreateArticleFormSlice";
import {CategoriesTreeReducer} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Slice/CategoriesTreeSlice";
import {
    ArticlesOfCategoryReducer
} from "@/features/InControlPanel/KnowledgeBase/ArticlesOfCategory/Slice/ArticlesOfCategorySlice";
import {OpenedArticleReducer} from "@/features/KnowledgeBase/OpenedArticle/Slice/OpenedArticleSlice";
import {
    ArticlesInTrashReducer
} from "@/features/InControlPanel/KnowledgeBase/ArticlesInTrash/Slice/ArticlesInTrashSlice";
import {
    EditArticleFormReducer
} from "@/features/InControlPanel/KnowledgeBase/EditArticleForm/Slice/EditArticleFormSlice";
import {
    KnowledgeBaseCategoriesInTrashReducer
} from "@/features/InControlPanel/KnowledgeBase/CategoriesInTrash/Slice/KnowledgeBaseCategoriesInTrashSlice";
import {
    KnowledgeBaseAddCategoryReducer
} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Slice/AddCategorySlice";
import {
    KnowledgeBaseEditCategoryReducer
} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Slice/EditCategorySlice";
import {PersonalManagerContactsReducer} from "@/features/PersonalManagerContacts/Slice/PersonalManagerContactsSlice";
import {CreateUserFormReducer} from "@/features/InControlPanel/Users/CreateUser/Slice/CreateUserFormSlice";
import {UsersListReducer} from "@/features/InControlPanel/Users/UsersList/Slice/UsersListSlice";
import {EditUserFormReducer} from "@/features/InControlPanel/Users/EditUser/Slice/EditUserFormSlice";
import {EditPartnerFormReducer} from "@/features/InControlPanel/EditPartnerForm/Slice/EditPartnerFormSlice";
import {EditPartnerPhysicalAddressReducer} from "@/features/InControlPanel/EditPartnerForm/Slice/EditPartnerPhysicalAddressSlice";
import {
    EditPartnerFormContactsReducer
} from "@/features/InControlPanel/EditPartnerForm/Slice/EditPartnerFormContactsSlice";
import {DeletingUserReducer} from "@/features/InControlPanel/Users/UsersList/Slice/DeletingUserSlice";
import {
    ApplicationsListReducer
} from "@/features/InControlPanel/ApplicationsForChangePersonalData/Slice/ApplicationsListSlice";
import {LangReducer} from "@/features/LangSwitcher/Slice/LangSlice";
import {CurrentUserReducer} from "@/features/Auth/CurrentUser/Slice/CurrentUserSlice";
import {CurrentCategoryReducer} from "@/features/KnowledgeBase/CategoriesTree/Slice/CurrentCategorySlice";
import { PartnerFirstEntryReducer } from "@/features/PartnerFirstEntryCheck/Slice/PartnerFirstEntrySlice";
import { CreateAppealFormReducer } from "@/features/Appeals/CreateAppealForm/Slice/CreateAppealFormSlice";
import { DNATestsReducer } from "@/features/DNATestsContent/Slice/DNATestsSlice";
import { CallCourierReducer } from "@/features/OrderDNATestForm/Slice/CallCourierSlice";
import { OpenedOrderLabFilesReducer } from "@/features/DNAOrderContent/Slice/OpenedOrderLabFilesSlice";
import { EditDNATestFormReducer } from "@/features/EditDNATestForm/Slice/EditDNATestFormSlice";
import { rtkQueryErrorLogger } from "./rtkQueryErrorLogger";
import { ShowGlobalErrorReducer } from "@/components/ShowGlobalError/Slice/ShowGlobalErrorSlice";

const Store = {
    reducer: {
        uiLeftMenu: UILeftMenuReducer,
        lang: LangReducer,
        currentUser: CurrentUserReducer,

        registerNewPartnerForm: RegisterNewPartnerFormReducer,
        registerNewPartnerPhysicalAddress: RegisterNewPartnerPhysicalAddressReducer,
        inCPEditPartnerForm: EditPartnerFormReducer,
        inCPEditPartnerFormPhysicalAddress: EditPartnerPhysicalAddressReducer,
        contacts: ContactsReducer,
        inCPEditPartnerFormContacts: EditPartnerFormContactsReducer,
        loginForm: LoginFormReducer,
        logout: LogoutReducer,

        orderDNATestForm: OrderDNATestFormReducer,
        callCourier: CallCourierReducer,

        editDNATestForm: EditDNATestFormReducer,

        dnaOrdersFiltersAndSorters: DNAOrdersFiltersAndSortersReducer,
        openedDNAOrder: OpenedDNAOrderReducer,
        openedDNAOrderLabFiles: OpenedOrderLabFilesReducer,

        allOrdersFiltersAndSorters: AllOrdersFiltersAndSortersReducer,
        allTestTypes: AllTestTypesReducer,
        inCPOpenedDNAOrder: InCPOpenedDNAOrderReducer,
        labDocumentsUploading: LabDocumentsUploadingReducer,
        inCPOpenedOrderLabFilesManipulations: InCPOpenedOrderLabFilesManipulationsReducer,

        inCPCreateArticleForm: CreateArticleFormReducer,
        inCPEditArticleForm: EditArticleFormReducer,
        inCPKnowledgeBaseCategoriesTree: CategoriesTreeReducer,
        inCPArticlesOfCategoryOfKnowledgeBase: ArticlesOfCategoryReducer,
        inCPArticlesInTrashInKnowledgeBase: ArticlesInTrashReducer,
        inCPCategoriesInTrashInKnowledgeBase: KnowledgeBaseCategoriesInTrashReducer,
        inCPAddCategoryInKnowledgeBase: KnowledgeBaseAddCategoryReducer,
        inCpEditCategoryInKnowledgeBase: KnowledgeBaseEditCategoryReducer,

        openedArticleOfKnowledgeBase: OpenedArticleReducer,
        currentCategoryOfKnowledgeBase: CurrentCategoryReducer,

        personalManagerContacts: PersonalManagerContactsReducer,

        inCPCreateUserForm: CreateUserFormReducer,
        inCPUsersList: UsersListReducer,
        inCPDeletingUser: DeletingUserReducer,
        inCPEditUserForm: EditUserFormReducer,

        inCPApplicationsList: ApplicationsListReducer,

        partnerFirstEntry: PartnerFirstEntryReducer,

        // appeals
        appealsCreateForm: CreateAppealFormReducer,

        //dna tests
        dnaTests: DNATestsReducer,

        globalError: ShowGlobalErrorReducer,

        [ApiSlice.reducerPath]: ApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware: () => any[]) =>
        getDefaultMiddleware().concat(ApiSlice.middleware, rtkQueryErrorLogger),
    devTools: isDevMode()
};

const configuredStore = configureStore(Store);
export default configuredStore;

export type RootState = ReturnType<typeof configuredStore.getState>;
