import {createTheme} from "@mui/material/styles";

export const InLabB2BTheme = createTheme({
    typography: {
        fontFamily: [
            "SFPro-Regular", "sans-serif"
        ].join(","),
        h1: {
            fontSize: "1.5rem",
            "@media (min-width:600px)": {
                fontSize: "2rem",
            },
            "@media (min-width:900px)": {
                fontSize: "2.5rem",
            },
            "@media (min-width:1200px)": {
                fontSize: "3rem",
            },
            "@media (min-width:1536px)": {
                fontSize: "3.5rem",
            },
        }
    },
    palette: {
        // primary: {
        //     main: "#87CDF0",
        //     dark: "#547d92",
        // },
        //secondary: {
            //main: "#fff",
        //},

        mode: 'light',
        primary: {
            main: '#307fa0',
            light: '#7098aa',
        },
        secondary: {
            // main: '#299855',
            main: '#87CDF0',
        },
    },
});
