import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import configuredStore, {RootState} from "@/store/Store";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios, {AxiosError} from "axios";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import {DNAOrderDataInterface} from "@/features/DNAOrderContent/Interfaces/DNAOrderDataInterface";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";

const restApiConfig = new RESTAPIConfig();

/**
 * @deprecated
 */
export const fetchOrderDataThunk = createAsyncThunk(
    "openedDNAOrder/fetchData",
    async ({orderNum}: {orderNum: string}) => {
        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getDNAOrderData({orderId: Number(orderNum)});

        const token = getAccessToken(configuredStore.getState());
        const userLang = configuredStore.getState().lang.userLang;

        try {
            const response = await axios.get<JsonResponseInterface<DNAOrderDataInterface>>(url, {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    [userLangHeaderName]: userLang,
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (response.data.data !== null) {
                configuredStore.dispatch(setOrder(response.data.data));
            }

        } catch (e: unknown) {
            const error = e as AxiosError;
            console.log(error);
        }
    }
);

function getInitialState() {
    return {
        loadingStatus: "idle" as LoadingStatus,
        order: null as DNAOrderDataInterface | null,
    }
}

export const OpenedDNAOrderSlice = createSlice({
    name: "OpenedDNAOrderSlice",
    initialState: getInitialState(),
    reducers: {
        setOrder: (state, action: PayloadAction<DNAOrderDataInterface>) => {
            state.order = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(fetchOrderDataThunk.pending, (state, actiion) => {
            state.loadingStatus = LoadingStatus.loading;
        })
        .addCase(fetchOrderDataThunk.fulfilled, (state, actiion) => {
            state.loadingStatus = LoadingStatus.succeeded;
        })
        .addCase(fetchOrderDataThunk.rejected, (state, actiion) => {
            state.loadingStatus = LoadingStatus.failed;
        })
    }
});

const {setOrder} = OpenedDNAOrderSlice.actions;
export const loadingStatusSelector = (state: RootState) => state.openedDNAOrder.loadingStatus;
export const openedDNAOrderSelector = (state: RootState) => state.openedDNAOrder.order;

export const OpenedDNAOrderReducer = OpenedDNAOrderSlice.reducer;