import { useDispatch, useSelector } from "react-redux";
import { leftMenuOpenedSelector, toggle } from "@/components/Menu/LeftMenu/Slice/UILeftMenuSlice";
import { Link as RouterLink } from "react-router-dom";
import Link from '@mui/material/Link';
import UrlsConfig from "@/config/UrlsConfig";
import { Drawer } from "@mui/material";
import Divider from '@mui/material/Divider';
import Logo from "@/components/Logo/Logo";
import styles from "./styles/LeftMenu.module.scss";
import { useWindowSize } from "@/hooks/useWindowSize";
import ListItemButton from '@mui/material/ListItemButton';
import { appRoles, doesUserRolesMatchRequired } from "@/common/appRoles";
import { useTranslation } from "react-i18next";
import { notAuthenticatedUserId, userSelector } from "@/features/Auth/CurrentUser/Slice/CurrentUserSlice";

export const desktopLeftMenuWidth = 240;
export const minWidthForDesktopLeftMenu = 1100;

const LeftMenu = () => {

    const { t } = useTranslation();

    const urlsConfig = new UrlsConfig();
    const dispatch = useDispatch();
    const opened = useSelector(leftMenuOpenedSelector);

    const size = useWindowSize();
    const windowWidth = size.width ?? 0;

    const drawerVariant = windowWidth > minWidthForDesktopLeftMenu ? "persistent" : "temporary";

    const user = useSelector(userSelector);
    const userLoggedIn = user.id !== notAuthenticatedUserId;

    const isPartner = doesUserRolesMatchRequired({
        suitableRoles: [appRoles.partner.alias],
        userRoles: user.roles.map(role => role.alias),
    });

    const isSupremeAdmin = doesUserRolesMatchRequired({
        suitableRoles: [appRoles.supremeAdmin.alias],
        userRoles: user.roles.map(role => role.alias),
    });

    const isContentManager = doesUserRolesMatchRequired({
        suitableRoles: [appRoles.contentManager.alias],
        userRoles: user.roles.map(role => role.alias),
    });

    const isManager = doesUserRolesMatchRequired({
        suitableRoles: [appRoles.manager.alias],
        userRoles: user.roles.map(role => role.alias),
    });

    const isAccountant = doesUserRolesMatchRequired({
        suitableRoles: [appRoles.accountant.alias],
        userRoles: user.roles.map(role => role.alias),
    });

    const isSeniorManager = doesUserRolesMatchRequired({
        suitableRoles: [appRoles.seniorManager.alias],
        userRoles: user.roles.map(role => role.alias),
    });

    const isAdmin = doesUserRolesMatchRequired({
        suitableRoles: [appRoles.admin.alias],
        userRoles: user.roles.map(role => role.alias),
    });

    return (
        <div>
            <Drawer
                sx={{
                    width: desktopLeftMenuWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: desktopLeftMenuWidth,
                        boxSizing: 'border-box',
                    },
                }}
                open={opened}
                variant={drawerVariant}
                anchor="left"
                onClose={() => {
                    dispatch(toggle());
                }}
            >

                <div className={styles.header}>
                    <Link component={RouterLink}
                        underline="none"
                        to={urlsConfig.index.to}
                        sx={{ height: "65%" }}
                    >
                        <Logo classes={styles.logo} />
                    </Link>
                </div>

                <Divider />

                {isSupremeAdmin &&
                    <>
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.createNewOrder.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Place an order on behalf of a partner")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelUsers.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Users")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.allOrders.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("All Orders")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelKnowledgeBase.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Knowledge base")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.feedback.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Feedback")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelApplicationsForChangePersonalData.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Requests for change of personal data")}
                            </ListItemButton>
                        </Link>
                    </>
                }

                {isAdmin &&
                    <>
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.createNewOrder.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Place an order on behalf of a partner")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelUsers.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Users")}
                            </ListItemButton>
                        </Link>
                        
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.allOrders.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("All Orders")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.feedback.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Feedback")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelApplicationsForChangePersonalData.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Requests for change of personal data")}
                            </ListItemButton>
                        </Link>
                    </>
                }

                {isManager &&
                    <>
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.createNewOrder.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Place an order on behalf of a partner")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelUsers.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Users")}
                            </ListItemButton>
                        </Link>
                        
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.allOrders.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("All Orders")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.feedback.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Feedback")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelApplicationsForChangePersonalData.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Requests for change of personal data")}
                            </ListItemButton>
                        </Link>
                    </>
                }

                {isAccountant &&
                    <>
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelUsers.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Users")}
                            </ListItemButton>
                        </Link>
                        
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.allOrders.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("All Orders")}
                            </ListItemButton>
                        </Link>
                    </>
                }

                {isPartner &&
                    <>
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.createNewOrder.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Place a new order")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.myOrders.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("My Orders")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.feedback.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Feedback")}
                            </ListItemButton>
                        </Link>

                        <Divider />
                    </>
                }

                {isContentManager &&
                    <>
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelKnowledgeBase.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Knowledge base")}
                            </ListItemButton>
                        </Link>

                        <Divider />
                    </>
                }

                {isSeniorManager &&
                    <>
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.createNewOrder.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Place an order on behalf of a partner")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelUsers.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Users")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.allOrders.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("All Orders")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.feedback.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Feedback")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.inControlPanelApplicationsForChangePersonalData.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Requests for change of personal data")}
                            </ListItemButton>
                        </Link>
                    </>
                }

                <Divider />

                {userLoggedIn &&
                    <>
                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.index.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Index")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.knowledgeBase.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("Knowledge base")}
                            </ListItemButton>
                        </Link>

                        <Link component={RouterLink}
                            underline="none"
                            to={urlsConfig.DNATests.to}
                            color="primary.dark"
                        >
                            <ListItemButton>
                                {t("DNA Tests")}
                            </ListItemButton>
                        </Link>
                    </>
                }

            </Drawer>
        </div>
    )
};


export default LeftMenu;