import {createAsyncThunk} from "@reduxjs/toolkit";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios, {AxiosError} from "axios";
import {showPopup} from "@/features/PopupMessages/PopupMessages";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import configuredStore from "@/store/Store";
import {
    setDeletingFileId, setDeletingFileMeaning, setDeletingFileName,
    setDeletionDialogOpening
} from "@/features/InControlPanel/DNAOrderContent/Slice/InCPOpenedOrderLabFilesManipulationsSlice";
import i18n from "@/i18n/i18n";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";

const restApiConfig = new RESTAPIConfig();

export const deleteFileThunk = createAsyncThunk(
    "openedDNAOrder/deleteFile",
    async ({fileId}: {fileId: string}) => {

        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getDeleteFileFromLabForOrder({fileId});
        const userLang = configuredStore.getState().lang.userLang;
        const token = getAccessToken(configuredStore.getState());
        try {
            const response = await axios.get<JsonResponseInterface>(url, {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    [userLangHeaderName]: userLang,
                    "Authorization": `Bearer ${token}`,
                },
            });

            const result = response.data.result;
            if (result === "success") {
                showPopup({
                    type: "success",
                    message: i18n.t("File deleted."),
                    autoClose: 5000,
                });

                configuredStore.dispatch(setDeletionDialogOpening(false));
                configuredStore.dispatch(setDeletingFileId(-1));
                configuredStore.dispatch(setDeletingFileMeaning(""));
                configuredStore.dispatch(setDeletingFileName(""));
            } else {
                showPopup({
                    type: "error",
                    message: response.data.errors.toString(),
                })
            }

            return response.data;

        } catch (e: unknown) {
            const error = e as AxiosError;
            //@ts-ignore
            const message = i18n.t("Error with code and text", {code: error.response.status, error: error.response.data.errors.toString()});
            showPopup({
                type: "error",
                message,
                autoClose: 5000,
            });
        }
    }
);