import {createAsyncThunk} from "@reduxjs/toolkit";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios from "axios";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import configuredStore from "@/store/Store";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";

export const saveNewCategoryThunk = createAsyncThunk(
    "addKnowledgeBaseCategory/save",
    async (
        {parentCategoryId, newCategoryTitle}:
        {parentCategoryId: string, newCategoryTitle: string}
    ) => {
        const restApiConfig = new RESTAPIConfig();
        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getKnowledgeBaseSaveNewCategory();
        const data = {
            parentCategoryId, newCategoryTitle,
        };

        const userLang = configuredStore.getState().lang.userLang;
        const token = getAccessToken(configuredStore.getState());
        try {
            const response = await axios.post<JsonResponseInterface<[]>>(url, data, {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    [userLangHeaderName]: userLang,
                    "Authorization": `Bearer ${token}`,
                }
            });

            return response.data;

        } catch (e: unknown) {}
    },
);