import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import {RootState} from "@/store/Store";
import {deleteFileThunk} from "@/features/InControlPanel/DNAOrderContent/Thunks/deleteFileThunk";

function getInitialState() {
    return {
        deletingFileId: -1,
        deletingFileMeaning: "",
        deletingFileName: "",

        deletionDialogIsOpened: false,
        deletingFileStatus: "idle" as LoadingStatus,
        downloadingFilesIds: [],
        gettingFilesForPrintingIds: [],
    }
}

export const InCPOpenedOrderLabFilesManipulationsSlice = createSlice({
    name: "InCPOpenedOrderLabFilesManipulationsSlice",
    initialState: getInitialState(),
    reducers: {
        setDeletionDialogOpening: (state, action: PayloadAction<boolean>) => {
            state.deletionDialogIsOpened = action.payload;
        },
        setDeletingFileId: (state, action: PayloadAction<number>) => {
            state.deletingFileId = action.payload;
        },
        setDeletingFileMeaning: (state, action: PayloadAction<string>) => {
            state.deletingFileMeaning = action.payload;
        },
        setDeletingFileName: (state, action: PayloadAction<string>) => {
            state.deletingFileName = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(deleteFileThunk.pending, (state, action) => {
            state.deletingFileStatus = LoadingStatus.loading;
        })
        .addCase(deleteFileThunk.fulfilled, (state, action) => {
            state.deletingFileStatus = LoadingStatus.succeeded;
        })
        .addCase(deleteFileThunk.rejected, (state, action) => {
            state.deletingFileStatus = LoadingStatus.failed;
        })
    }
});

export const {
    setDeletionDialogOpening,
    setDeletingFileId,
    setDeletingFileMeaning,
    setDeletingFileName,
} = InCPOpenedOrderLabFilesManipulationsSlice.actions;

export const deletingFileIdSelector = (state: RootState) => state.inCPOpenedOrderLabFilesManipulations.deletingFileId;
export const deletingFileMeaningSelector = (state: RootState) => state.inCPOpenedOrderLabFilesManipulations.deletingFileMeaning;
export const deletingFileNameSelector = (state: RootState) => state.inCPOpenedOrderLabFilesManipulations.deletingFileName;
export const deletingFileStatusSelector = (state: RootState) => state.inCPOpenedOrderLabFilesManipulations.deletingFileStatus;
export const deletionDialogIsOpenedSelector = (state: RootState) => state.inCPOpenedOrderLabFilesManipulations.deletionDialogIsOpened;

export const InCPOpenedOrderLabFilesManipulationsReducer = InCPOpenedOrderLabFilesManipulationsSlice.reducer;