import {createSlice} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import {RootState} from "@/store/Store";
import {
    getArticlesInTrashCountThunk
} from "@/features/InControlPanel/KnowledgeBase/ArticlesInTrash/Thunks/getArticlesInTrashCountThunk";
import {
    deleteArticlesInTrashThunk
} from "@/features/InControlPanel/KnowledgeBase/ArticlesInTrash/Thunks/deleteArticlesInTrashThunk";

function getInitialState() {
    return {
        inTrashCount: 0,
        inTrashCountIsLoading: LoadingStatus.idle,
        deleteArticlesInTrashDialogOpened: false,
    }
}

export const ArticlesInTrashSlice = createSlice({
    name: "ArticlesInTrashSlice",
    initialState: getInitialState(),
    reducers: {
        setDeleteDialogVisibility: (state, action) => {
            state.deleteArticlesInTrashDialogOpened = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(getArticlesInTrashCountThunk.pending, (state, action) => {
            state.inTrashCountIsLoading = LoadingStatus.loading;
        })
        .addCase(getArticlesInTrashCountThunk.fulfilled, (state, action) => {
            state.inTrashCountIsLoading = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success") {
                if (action.payload.data !== null) {
                    state.inTrashCount = action.payload.data.inTrash;
                }
            }
        })
        .addCase(getArticlesInTrashCountThunk.rejected, (state, action) => {
            state.inTrashCountIsLoading = LoadingStatus.failed;
        })

        .addCase(deleteArticlesInTrashThunk.fulfilled, (state, action) => {
            state.deleteArticlesInTrashDialogOpened = false;

            if (action.payload === undefined) {
                return;
            }

            //@ts-ignore
            if (action.payload.result === "success") {
                state.inTrashCount = 0;
            }
        })
    },
});

export const {
    setDeleteDialogVisibility
} = ArticlesInTrashSlice.actions;

export const inTrashCountSelector = (state: RootState) => state.inCPArticlesInTrashInKnowledgeBase.inTrashCount;
export const inTrashCountIsLoadingSelector = (state: RootState) => state.inCPArticlesInTrashInKnowledgeBase.inTrashCountIsLoading;
export const deleteArticlesInTrashDialogOpenedSelector = (state: RootState) => state.inCPArticlesInTrashInKnowledgeBase.deleteArticlesInTrashDialogOpened;

export const ArticlesInTrashReducer = ArticlesInTrashSlice.reducer;