export function isDevMode() {
    return process.env.NODE_ENV === "development";
}

export function isProdMode() {
    return process.env.NODE_ENV === "production";
}

export function getMode() {
    switch (window.location.port) {
        case "30080":
            return "test";
        case "":
            return "production";
        default:
            return "development";
    }
}