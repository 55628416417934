import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "@/store/Store";
import {LoadingStatus} from "@/store/LoadingStatus";
import {
    getCategoriesInTrashCountThunk
} from "@/features/InControlPanel/KnowledgeBase/CategoriesInTrash/Thunks/getCategoriesInTrashCountThunk";
import {
    deleteCategoriesInTrashThunk
} from "@/features/InControlPanel/KnowledgeBase/CategoriesInTrash/Thunks/deleteCategoriesInTrashThunk";

function getInitialState() {
    return {
        inTrash: 0,
        dialogIsOpened: false,
        fetchCategoriesInTrashRequestStatus: LoadingStatus.idle as LoadingStatus,
        deleteCategoriesRequestStatus: LoadingStatus.idle as LoadingStatus,
    };
}

export const KnowledgeBaseCategoriesInTrashSlice = createSlice({
    name: "KnowledgeBaseCategoriesInTrashSlice",
    initialState: getInitialState(),
    reducers: {
        setDialogIsOpen: (state, action) => {
            state.dialogIsOpened = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(getCategoriesInTrashCountThunk.pending, (state, action) => {
            state.fetchCategoriesInTrashRequestStatus = LoadingStatus.loading;
        })
        .addCase(getCategoriesInTrashCountThunk.fulfilled, (state, action) => {
            state.fetchCategoriesInTrashRequestStatus = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success") {
                if (action.payload.data !== null) {
                    //@ts-ignore
                    state.inTrash = action.payload.data.inTrash;
                }
            }
        })
        .addCase(getCategoriesInTrashCountThunk.rejected, (state, action) => {
            state.fetchCategoriesInTrashRequestStatus = LoadingStatus.failed;
        })

        .addCase(deleteCategoriesInTrashThunk.pending, (state, action) => {
            state.deleteCategoriesRequestStatus = LoadingStatus.loading;
        })
        .addCase(deleteCategoriesInTrashThunk.fulfilled, (state, action) => {
            state.deleteCategoriesRequestStatus = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success") {
                state.dialogIsOpened = false;
            }
        })
        .addCase(deleteCategoriesInTrashThunk.rejected, (state, action) => {
            state.deleteCategoriesRequestStatus = LoadingStatus.failed;
        })
    },
});

export const {
    setDialogIsOpen,
} = KnowledgeBaseCategoriesInTrashSlice.actions;

export const categoriesInTrashSelector = (state: RootState) => state.inCPCategoriesInTrashInKnowledgeBase.inTrash;
export const fetchInTrashCategoriesStatusSelector = (state: RootState) => state.inCPCategoriesInTrashInKnowledgeBase.fetchCategoriesInTrashRequestStatus;
export const dialogIsOpenSelector = (state: RootState) => state.inCPCategoriesInTrashInKnowledgeBase.dialogIsOpened;
export const deleteCategoriesStatusSelector = (state: RootState) => state.inCPCategoriesInTrashInKnowledgeBase.deleteCategoriesRequestStatus;

export const KnowledgeBaseCategoriesInTrashReducer = KnowledgeBaseCategoriesInTrashSlice.reducer;