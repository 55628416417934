import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "@/store/Store";

function getInitialState() {
    return {
        managerContactsDialogIsOpened: false,
    }
}

export const PersonalManagerContactsSlice = createSlice({
    name: "PersonalManagerContactsSlice",
    initialState: getInitialState(),
    reducers: {
        toggleManagerContactsDialogOpening: (state, action) => {
            state.managerContactsDialogIsOpened = action.payload;
        },
    }
});

export const managerContactsDialogIsOpenedSelector = (state: RootState) => state.personalManagerContacts.managerContactsDialogIsOpened;

export const {
    toggleManagerContactsDialogOpening,
} = PersonalManagerContactsSlice.actions;

export const PersonalManagerContactsReducer = PersonalManagerContactsSlice.reducer;