import { RootState } from "@/store/Store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PartnerMainInfoInterface } from "@/features/PartnerFirstEntryCheck/Interfaces/PartnerMainInfoInterface";
import { PhysicalAddressOfPartnerInterface } from "@/api/Users/GetCurrentUser/Interfaces/PhysicalAddressOfPartnerInterface";
import { GetCurrentUserApiSlice } from "@/api/Users/GetCurrentUser/GetCurrentUserApiSlice";
import { getRandomInteger } from "@/helpers/getRandomInteger";
import { ContactOfPartnerInterface } from "@/api/Users/GetCurrentUser/Interfaces/ContactOfPartnerInterface";
import { ContactRoleInterface } from "@/features/PartnerFirstEntryCheck/Interfaces/ContactRoleInterface";

export const notSetAddNewContactFormId = -1;

function getInitialState() {
    return {
        checkPartnerDataDialogVisible: false,
        newDataFieldsOpened: false,

        mainPartnerInfo: {
            fullNameLegal: "",
            shortNameLegal: "",
            legalAddress: "",
            inn: "",
            kpp: "",
            ogrn: "",
            nameOfBank: "",
            checkingAccount: "",
            correspondentAccount: "",
            bik: "",
        } as PartnerMainInfoInterface,

        physicalAddresses: [] as PhysicalAddressOfPartnerInterface[],
        newPhysicalAddress: "",
        physicalAddressesChanged: false,
        addPhysicalAddressFormVisible: false,

        contactsOfPartner: [] as ContactOfPartnerInterface[],
        newContact: "",
        contactsChanged: false,
        addContactFormVisible: false,

        addNewContactForm: {
            id: notSetAddNewContactFormId,
            contact_role: {
                alias: "",
                id: notSetAddNewContactFormId,
                title: "",
            },
            fio: "",
            phone: "",
            email: "",
            position: "",
            note: "",
        } as ContactOfPartnerInterface,

        allValidationErrors: [] as string[],
    }
}

export const PartnerFirstEntrySlice = createSlice({
    name: "PartnerFirstEntrySlice",
    initialState: getInitialState(),
    reducers: {
        resetPartnerFirstEntry: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            
            state.checkPartnerDataDialogVisible = initialState.checkPartnerDataDialogVisible;
            state.newDataFieldsOpened = initialState.newDataFieldsOpened;

            state.mainPartnerInfo = initialState.mainPartnerInfo;

            state.physicalAddresses = initialState.physicalAddresses;
            state.newPhysicalAddress = initialState.newPhysicalAddress;
            state.physicalAddressesChanged = initialState.physicalAddressesChanged;
            state.addPhysicalAddressFormVisible = initialState.addPhysicalAddressFormVisible;

            state.contactsOfPartner = initialState.contactsOfPartner;
            state.newContact = initialState.newContact;
            state.contactsChanged = initialState.contactsChanged;
            state.addContactFormVisible = initialState.addContactFormVisible;

            state.addNewContactForm = initialState.addNewContactForm;
        },
        setDialogVisibility: (state, action: PayloadAction<boolean>) => {
            state.checkPartnerDataDialogVisible = action.payload;
        },
        setNewDataFieldsOpened: (state, action: PayloadAction<boolean>) => {
            state.newDataFieldsOpened = action.payload;
        },
        setOneOfMainPartnerInfo: (state, action: PayloadAction<{ name: keyof PartnerMainInfoInterface, value: string, }>) => {
            state.mainPartnerInfo[action.payload.name] = action.payload.value;
        },
        resetMainInfo: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.mainPartnerInfo = initialState.mainPartnerInfo;
        },

        changePhysicalAddress: (state, action: PayloadAction<{ id: number, value: string }>) => {
            state.physicalAddresses = state.physicalAddresses.map(address => {
                if (address.id !== action.payload.id) {
                    return address;
                }
                address.physicalAddress = action.payload.value;
                return address;
            })
        },
        deletePhysicalAddress: (state, action: PayloadAction<number>) => {
            state.physicalAddresses = state.physicalAddresses.filter(address => {
                return address.id !== action.payload;
            })
        },
        changeVisibilityForAddNewAddressForm: (state, action: PayloadAction<boolean>) => {
            state.addPhysicalAddressFormVisible = action.payload;
        },
        changeNewPhysicalAddress: (state, action: PayloadAction<string>) => {
            state.newPhysicalAddress = action.payload;
        },
        addPhysicalAddress: (state, action: PayloadAction<void>) => {
            state.physicalAddresses.push({
                id: getRandomInteger(),
                physicalAddress: state.newPhysicalAddress,
            })
        },
        changePhysicalAddressesChanged: (state, action: PayloadAction<boolean>) => {
            state.physicalAddressesChanged = action.payload;
        },

        changeContact: (state, action: PayloadAction<{contactId: number, name: string, value: string}>) => {
            state.contactsOfPartner = state.contactsOfPartner.map(contact => {
                if (action.payload.contactId !== contact.id) {
                    return contact;
                }
                const newContact = {...contact};
                //@ts-ignore
                newContact[action.payload.name] = action.payload.value;
                return newContact;
            });
        },
        changeContactRole: (state, action: PayloadAction<{contactId: number, role: ContactRoleInterface}>) => {
            state.contactsOfPartner = state.contactsOfPartner.map(contact => {
                if (action.payload.contactId !== contact.id) {
                    return contact;
                }
                const newContact = {...contact};
                newContact.contact_role = action.payload.role;
                return newContact;
            });
        },
        changeContactsChanged: (state, action: PayloadAction<boolean>) => {
            state.contactsChanged = action.payload;
        },
        changeVisibilityForAddNewContactForm: (state, action: PayloadAction<boolean>) => {
            state.addContactFormVisible = action.payload;
        },
        changeNewContactFormValue: (state, action: PayloadAction<{name: string, value: string|number}>) => {
            //@ts-ignore
            state.addNewContactForm[action.payload.name] = action.payload.value;
        },
        setContactRoleForNewContactForm: (state, action: PayloadAction<{role: ContactRoleInterface}>) => {
            state.addNewContactForm.contact_role = action.payload.role;
        },
        addContact: (state, action: PayloadAction<void>) => {
            state.contactsOfPartner.push({
                id: state.addNewContactForm.id,
                fio: state.addNewContactForm.fio,
                phone: state.addNewContactForm.phone,
                email: state.addNewContactForm.email,
                position: state.addNewContactForm.position,
                note: state.addNewContactForm.note,
                contact_role: state.addNewContactForm.contact_role,
            })
        },
        deleteContact: (state, action: PayloadAction<number>) => {
            state.contactsOfPartner = state.contactsOfPartner.filter(contact => {
                return contact.id !== action.payload;
            })
        },

        setAllValidationErrors: (state, action: PayloadAction<string[]>) => {
            state.allValidationErrors = action.payload;
        }
    },

    extraReducers(builder) {
        builder
            .addMatcher(GetCurrentUserApiSlice.endpoints.getCurrentUser.matchFulfilled, (state, action) => {
                if (action.payload.result === "success" && action.payload.data.partner !== null) {
                    state.physicalAddresses = action.payload.data.partner.physical_addresses_of_partners;
                    state.contactsOfPartner = action.payload.data.partner.contacts_of_partners;
                }
            })
    }
});

export const {
    setDialogVisibility,
    setNewDataFieldsOpened,
    setOneOfMainPartnerInfo,
    resetMainInfo,
    changePhysicalAddress,
    deletePhysicalAddress,
    changeVisibilityForAddNewAddressForm,
    changeNewPhysicalAddress,
    addPhysicalAddress,
    changePhysicalAddressesChanged,
    changeContact,
    changeContactsChanged,
    changeVisibilityForAddNewContactForm,
    changeNewContactFormValue,
    addContact,
    deleteContact,
    changeContactRole,
    setContactRoleForNewContactForm,
    resetPartnerFirstEntry,
    setAllValidationErrors,
} = PartnerFirstEntrySlice.actions;

export const checkPartnerDataDialogVisibleSelector = (state: RootState) => state.partnerFirstEntry.checkPartnerDataDialogVisible;
export const newDataFieldsOpenedSelector = (state: RootState) => state.partnerFirstEntry.newDataFieldsOpened;

export const physicalAddressesSelector = (state: RootState) => state.partnerFirstEntry.physicalAddresses;
export const addPhysicalAddressFormVisibleSelector = (state: RootState) => state.partnerFirstEntry.addPhysicalAddressFormVisible;
export const newPhysicalAddressSelector = (state: RootState) => state.partnerFirstEntry.newPhysicalAddress;
export const physicalAddressesChangedSelector = (state: RootState) => state.partnerFirstEntry.physicalAddressesChanged;

export const contactsSelector = (state: RootState) => state.partnerFirstEntry.contactsOfPartner;
export const addContactFormVisibleSelector = (state: RootState) => state.partnerFirstEntry.addContactFormVisible;
export const addContactFormValuesSelector = (state: RootState) => state.partnerFirstEntry.addNewContactForm;

export const getSelectorMainInfoFor = (name: keyof PartnerMainInfoInterface) => (state: RootState) => state.partnerFirstEntry.mainPartnerInfo[name];

export const contactsChangedSelector = (state: RootState) => state.partnerFirstEntry.contactsChanged;
export const addressesChangedSelector = (state: RootState) => state.partnerFirstEntry.physicalAddressesChanged;

export const allValidationErrorsSelector = (state: RootState) => state.partnerFirstEntry.allValidationErrors;

export const isSomeInfoChangedSelector = (state: RootState) => {
    let mainInfoChanged = false;
    for (const unit in state.partnerFirstEntry.mainPartnerInfo) {
        //@ts-ignore
        if (state.partnerFirstEntry.mainPartnerInfo[unit] !== "") {
            mainInfoChanged = true;
            break;
        }
    }
    return mainInfoChanged || 
           state.partnerFirstEntry.physicalAddressesChanged || 
           state.partnerFirstEntry.contactsChanged
           ;
};

export const PartnerFirstEntryReducer = PartnerFirstEntrySlice.reducer;