import styles from "./CircularLoading.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";

const CircularLoading = ({text = ""}: {text?: string}) => {

    const { t } = useTranslation();

    const textTrans = text === "" ? t("Loading...") : text;

    return (
        <div className={styles.wrap}>
            <CircularProgress /> {textTrans}
        </div>
    )
}

export {CircularLoading}