import { TestInterface } from "@/api/Analysis/AllTypes/Interfaces/TestInterface";
import { RootState } from "@/store/Store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const notSetTestId = -1;

function getInitialState() {
    return {
        testDetailsModalVisible: false,

        testDetailsModalData: {
            alias: "",
            deleted_at: null,
            short_description: "",
            id: notSetTestId,
            image: "",
            index: notSetTestId,
            full_description: "",
            title: "",
        } as TestInterface,

        addEditTestModalVisible: false,
        addEditFormVariant: "add" as "add" | "edit",

        addEditTestForm: {
            id: "",

            serialNumber: "",
            indexError: "",

            title: "",
            titleError: "",

            alias: "",
            aliasError: "",

            currentPreviewUrl: "",
            newPreviewFileName: "",

            shortDesc: "",
            short_descriptionError: "",

            fullDesc: "",
            full_descriptionError: "",

            previewValidationErrors: [] as string[],
        },
    };
}

export const DNATestsSlice = createSlice({
    name: "DNATestsSlice",
    initialState: getInitialState(),
    reducers: {
        changeModalVisible: (state, action: PayloadAction<{name: "details"|"addEdit", visible: boolean}>) => {
            const {name, visible} = action.payload;
            
            switch (name) {
                case "details":
                    state.testDetailsModalVisible = visible;
                    break;
                case "addEdit":
                    state.addEditTestModalVisible = visible;
                    break;
            }
            
        },
        clearModalData: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.testDetailsModalData = initialState.testDetailsModalData;
        },
        setTestDetailsData: (state, action: PayloadAction<TestInterface>) => {
            state.testDetailsModalData = action.payload;
        },

        setAddEditTestFormValue: (state, action: PayloadAction<{name: string, value: string,}>) => {
            const {name, value} = action.payload;
            //@ts-ignore
            state.addEditTestForm[name] = value;
        },
        setAddEditFormVariant: (state, action: PayloadAction<"add" | "edit">) => {
            state.addEditFormVariant = action.payload;
        },
        resetAddEditTestForm: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.addEditTestForm = initialState.addEditTestForm;
        },
        setPreviewValidationErrors: (state, action: PayloadAction<string[]>) => {
            state.addEditTestForm.previewValidationErrors = action.payload;
        },
    }
});

export const {
    changeModalVisible,
    clearModalData,
    setTestDetailsData,
    setAddEditTestFormValue,
    setAddEditFormVariant,
    resetAddEditTestForm,
    setPreviewValidationErrors,
} = DNATestsSlice.actions;

export const testDetailsModalVisibleSelector = (state: RootState) => state.dnaTests.testDetailsModalVisible;
export const testDetailsModalDataSelector = (state: RootState) => state.dnaTests.testDetailsModalData;
export const addEditTestFormSelector = (state: RootState) => state.dnaTests.addEditTestForm;
export const addEditModalVisibleSelector = (state: RootState) => state.dnaTests.addEditTestModalVisible;
export const addEditVariantSelector = (state: RootState) => state.dnaTests.addEditFormVariant;

export const DNATestsReducer = DNATestsSlice.reducer;