import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "@/store/Store";
import { EditUserDataInterface } from "@/features/InControlPanel/Users/EditUser/Interfaces/EditUserDataInterface";
import { RoleInterface } from "@/features/Auth/LoginForm/Interfaces/RoleInterface";
import { UpdateUserValidationErrorsInterface } from "@/api/Users/Update/Interfaces/UpdateUserValidationErrorsInterface";

export const notInitializedId = -1;

function getInitialState() {
    return {
        user: {
            created_at: "",
            email: "",
            id: notInitializedId,
            image: "",
            is_first_entry: 0,
            name: "",
            partner: null,
            position: "",
            roles: [{id: notInitializedId, alias: "", title: "",}] as RoleInterface[],
            contactEmail: "",
            schedule: "",
            senior_manager: null,
            tel: "",
        } as EditUserDataInterface,
        
        password: "",
        repeatPassword: "",

        selectedSeniorManagerId: "",

        validationErrors: {} as UpdateUserValidationErrorsInterface,
    }
}

export const EditUserFormSlice = createSlice({
    name: "EditUserFormSlice",
    initialState: getInitialState(),
    reducers: {
        setUserValues: (state, action: PayloadAction<EditUserDataInterface>) => {
            state.user.email = action.payload.email;
            state.user.id = action.payload.id;
            state.user.name = action.payload.name;
            state.user.roles = action.payload.roles;
            state.user.position = action.payload.position;
            state.user.tel = action.payload.tel;
            state.user.schedule = action.payload.schedule;
            state.user.contactEmail = action.payload.contact_email;
            if (action.payload.senior_manager !== null) {
                state.selectedSeniorManagerId = action.payload.senior_manager.id.toString();
            }
        },
        
        changeUserValue: (state, action: PayloadAction<{name: keyof EditUserDataInterface, value: any}>) => {
            //@ts-ignore
            state.user[action.payload.name] = action.payload.value;
        },
        changePasswords: (state, action: PayloadAction<{name: "password"|"repeatPassword", value: string}>) => {
            state[action.payload.name] = action.payload.value;
        },
        changeSeniorManager: (state, action: PayloadAction<string>) => {
            state.selectedSeniorManagerId = action.payload;
        },
        resetForm: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.user = initialState.user;
            state.password = initialState.password;
            state.repeatPassword = initialState.repeatPassword;
            state.selectedSeniorManagerId = initialState.selectedSeniorManagerId;
        },

        setValidationErrors: (state, action: PayloadAction<UpdateUserValidationErrorsInterface>) => {
            state.validationErrors = action.payload;
        },
    },
});

export const {
    setUserValues,
    changeUserValue,
    changePasswords,
    changeSeniorManager,
    resetForm,
    setValidationErrors,
} = EditUserFormSlice.actions;

export const userRolesSelector = (state: RootState) => state.inCPEditUserForm.user.roles;
export const selectedSeniorManagerIdSelector = (state: RootState) => state.inCPEditUserForm.selectedSeniorManagerId;
export const editUserFormStateSelector = (state: RootState) => state.inCPEditUserForm;

export const isFormReadyToSendSelector = (state: RootState) => {
    const userFieldsValid = state.inCPEditUserForm.user.roles.length > 0 &&
           state.inCPEditUserForm.user.name.trim() !== "" &&
           state.inCPEditUserForm.user.email.trim() !== "" &&
           state.inCPEditUserForm.user.position.trim() !== "" &&
           state.inCPEditUserForm.user.tel.trim() !== "" &&
           state.inCPEditUserForm.user.schedule.trim() !== "";

    let seniorManagerValid = true;
    const selectedRolesContainsManager = state.inCPEditUserForm.user.roles.find(role => role.alias === "manager") !== undefined;
    if (selectedRolesContainsManager) {
        seniorManagerValid = state.inCPEditUserForm.selectedSeniorManagerId !== "";
    }

    return userFieldsValid && seniorManagerValid;
};

export const EditUserFormReducer = EditUserFormSlice.reducer;