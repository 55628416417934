import { PhysicalAddressOfPartnerInterface } from "@/api/Users/GetCurrentUser/Interfaces/PhysicalAddressOfPartnerInterface";
import { getRandomInteger } from "@/helpers/getRandomInteger";
import { RootState } from "@/store/Store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    addresses: [] as PhysicalAddressOfPartnerInterface[],
    formStage: "new",
    form: {
        id: "",
        addressField: "",
    }
};

export const EditPartnerPhysicalAddressSlice = createSlice({
    name: "EditPartnerPhysicalAddressSlice",
    initialState,
    reducers: {
        setAddressField: (state, action) => {
            state.form.addressField = action.payload;
        },
        setAddresses: (state, action) => {
            state.addresses = action.payload;
        },
        addAddress: (state, action) => {
            state.addresses.push({id: getRandomInteger(), physicalAddress: action.payload});
            state.form.id = "";
            state.form.addressField = "";
        },
        editAddress: (state, action: PayloadAction<{id: number, newAddress: string}>) => {
            const {id, newAddress} = action.payload;
            const editingAddress = state.addresses.find(address => address.id === id) as PhysicalAddressOfPartnerInterface;
            editingAddress.physicalAddress = newAddress;
            state.formStage = FormStage.new;
            state.form.id = "";
            state.form.addressField = "";
        },
        deleteAddress: (state, action) => {
            const deletingAddressId = action.payload.id;
            state.addresses = state.addresses.filter(address => address.id !== deletingAddressId);
            state.formStage = FormStage.new;
            state.form.id = "";
            state.form.addressField = "";
        },
        setAddressForm: (state, action) => {
            state.form.id = action.payload.id;
            state.form.addressField = action.payload.addressField;
        },
        setFormStage: (state, action) => {
            state.formStage = action.payload;
        },
        setToDefault: (state, action) => {
            state.addresses = [];
            state.formStage = FormStage.new;
            state.form.id = "";
            state.form.addressField = "";
        },
    }
});

export const {
    setAddressField,
    addAddress,
    editAddress,
    setAddressForm,
    setFormStage,
    deleteAddress,
    setToDefault,
    setAddresses,
} = EditPartnerPhysicalAddressSlice.actions;

export const formSelector = (state: RootState) => state.inCPEditPartnerFormPhysicalAddress.form;
export const addressesSelector = (state: RootState) => state.inCPEditPartnerFormPhysicalAddress.addresses;
export const addressFieldSelector = (state: RootState) => state.inCPEditPartnerFormPhysicalAddress.form.addressField;
export const formStageSelector = (state: RootState) => state.inCPEditPartnerFormPhysicalAddress.formStage;
export const physicalAddressesAreCorrectSelector = (state: RootState) => state.inCPEditPartnerFormPhysicalAddress.addresses.length > 0;

export const EditPartnerPhysicalAddressReducer = EditPartnerPhysicalAddressSlice.reducer;

export const FormStage = {
    new: "new",
    edit: "edit",
}