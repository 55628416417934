import {createAsyncThunk} from "@reduxjs/toolkit";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios, {AxiosError} from "axios";
import {showPopup} from "@/features/PopupMessages/PopupMessages";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import {
    CreateArticleFormInterface
} from "@/features/InControlPanel/KnowledgeBase/CreateArticleForm/Interfaces/CreateArticleFormInterface";
import i18n from "@/i18n/i18n";
import configuredStore from "@/store/Store";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";
import { v4 as uuidv4 } from 'uuid';

const restApiConfig = new RESTAPIConfig();

export const saveArticleThunk = createAsyncThunk(
    "createArticleForm/save",
    async ({data, preview}: {data: CreateArticleFormInterface, preview: File}) => {
        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getSaveKnowledgeBaseArticle();
        let response;
        const userLang = configuredStore.getState().lang.userLang;
        const token = getAccessToken(configuredStore.getState());

        const newData = {...data};
        
        if (preview) {
            let filesMetadata = [{
                id: uuidv4(),
                fileName: preview.name,
                meaning: "preview",
            }];

            //@ts-ignore
            newData.filesMetadataJson = JSON.stringify(filesMetadata);
            //@ts-ignore
            newData.files = [preview];
        }

        try {
            // const newData = {...data, filesMetadataJson: JSON.stringify(filesMetadata), files: [preview]};

            response = await axios.post<JsonResponseInterface<null>>(url, newData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    [userLangHeaderName]: userLang,
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (response.data.result === "success") {
                showPopup({
                    type: "success",
                    message: i18n.t("The article was successfully saved."),
                    autoClose: 5000,
                })
            }

            return response.data;

        } catch (e: unknown) {
            const error = e as AxiosError;
            //@ts-ignore
            const responseStatus = error.response.status;
            //@ts-ignore
            const commonErrorsText = error.response.data.errors.toString().trim();
            const errorText = commonErrorsText !== "" ? commonErrorsText : "";
            const message = i18n.t("Error with code and text", {code: responseStatus, error: errorText});
            showPopup({
                type: "error",
                message,
                autoClose: 5000,
            });

            //@ts-ignore
            return e.response.data;
        }
    },
)