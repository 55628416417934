import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store/Store";
import { SaveUserValidationErrorsInterface } from "@/api/Users/Create/Interfaces/SaveUserValidationErrorsInterface";

function getInitialState() {
    return {
        form: {
            selectedRoleAlias: "",
            fio: "",
            email: "",
            password: "",
            repeatPassword: "",
            photoFileName: "",
            photoFileSizeInBytes: "",
            position: "",
            phone: "",
            contactEmail: "",
            schedule: "",
            selectedSeniorManagerId: "",

            validationErrors: {} as SaveUserValidationErrorsInterface,
        },
    }
}

export const CreateUserFormSlice = createSlice({
    name: "CreateUserFormSlice",
    initialState: getInitialState(),
    reducers: {
        setValue: (state, action) => {
            const name = action.payload.name as string;
            const value = action.payload.value as string;
            //@ts-ignore
            state.form[name] = value;
        },
        resetForm: (state) => {
            const initialState = getInitialState();
            state.form = initialState.form;
        },
    }
});

export const {
    setValue,
    resetForm,
} = CreateUserFormSlice.actions;

//@ts-ignore
export const getSelectorFor = (name: string) => (state: RootState) => state.inCPCreateUserForm[name];
export const isFormReadyToSendSelector = (state: RootState) => {
    const mainFieldsValid = state.inCPCreateUserForm.form.selectedRoleAlias !== "" &&
        state.inCPCreateUserForm.form.fio.trim() !== "" &&
        state.inCPCreateUserForm.form.email.trim() !== "" &&
        state.inCPCreateUserForm.form.password.trim() !== "" &&
        state.inCPCreateUserForm.form.repeatPassword.trim() !== "" &&
        //    state.inCPCreateUserForm.photoFileName !== "" &&
        state.inCPCreateUserForm.form.position.trim() !== "" &&
        state.inCPCreateUserForm.form.phone.trim() !== "" &&
        state.inCPCreateUserForm.form.contactEmail.trim() !== "" &&
        state.inCPCreateUserForm.form.schedule.trim() !== "";

    let seniorManagerValid = true;
    if (state.inCPCreateUserForm.form.selectedRoleAlias === "manager") {
        seniorManagerValid = state.inCPCreateUserForm.form.selectedSeniorManagerId !== "";
    }

    return mainFieldsValid && seniorManagerValid;
};

export const createUserFormStateSelector = (state: RootState) => state.inCPCreateUserForm.form;

export const CreateUserFormReducer = CreateUserFormSlice.reducer;