export default class UrlsConfig {
    get login() {
        return {
            to: "/login",
        }
    }

    get index() {
        return {
            to: "/",
        }
    }

    get profile() {
        return {
            to: "/profile",
        }
    }

    get knowledgeBase() {
        return {
            to: "/knowledge-base",
        }
    }

    get knowledgeBaseSearchResults() {
        return {
            to: `${this.knowledgeBase.to}/search-results`,
        }
    }

    public articleOfKnowledgeBase({articleId}: {articleId: string}) {
        return {
            to: `${this.knowledgeBase.to}/article/${articleId}`,
        };
    }

    get DNATests() {
        return {
            to: "/dna-tests",
        }
    }

    get page404() {
        return {
            to: "/404",
        }
    }

    get pageForbidden() {
        return {
            to: "/forbidden",
        }
    }

    get createNewOrder() {
        return {
            to: "/create-new-order",
        }
    }

    get errorOrComplain() {
        return {
            to: "/error-or-complain",
        }
    }

    get feedback() {
        return {
            to: "/feedback",
        }
    }

    get createNewAppeal() {
        return {
            to: "/feedback/create-new-appeal",
        }
    }

    get openAppeal() {
        return {
            to: "/feedback/appeal",
        }
    }

    get controlPanel() {
        return {
            to: "/control-panel",
        }
    }

    get inControlPanelUsers() {
        return {
            to: `${this.controlPanel.to}/users`,
        }
    }

    get inControlPanelCreateUser() {
        return {
            to: `${this.controlPanel.to}/users/create`,
        }
    }

    get inControlPanelEditUser() {
        return {
            to: `${this.controlPanel.to}/users/edit`,
        };
    }

    get inControlPanelApplicationsForChangePersonalData() {
        return {
            to: `${this.controlPanel.to}/applications-for-change-personal-data`,
        }
    }

    get inControlPanelViewApplicationForChangePersonalData() {
        return {
            to: `${this.inControlPanelApplicationsForChangePersonalData.to}/view-application`,
        }
    }

    /**
     * @deprecated
     */
    get inControlPanelFeedback() {
        return {
            to: `${this.controlPanel.to}/feedback`,
        }
    }

    get registerNewPartner() {
        return {
            to: `${this.controlPanel.to}/register-new-partner`,
        }
    }

    get editPartner() {
        return {
            to: `${this.controlPanel.to}/edit-partner`,
        }
    }

    get allOrders() {
        return {
            to: `${this.controlPanel.to}/all-orders`,
        }
    }

    get openedOrderInControlPanel() {
        return {
            to: `${this.allOrders.to}/order`,
        }
    }

    get openedOrderForStaff() {
        return {
            to: `${this.controlPanel.to}/all-orders/order`,
        }
    }

    get inControlPanelKnowledgeBase() {
        return {
            to: `${this.controlPanel.to}/knowledge-base`,
        }
    }

    get inControlPanelKnowledgeBaseCreateArticle() {
        return {
            to: `${this.inControlPanelKnowledgeBase.to}/create-article`,
        }
    }

    get inControlPanelKnowledgeBaseEditArticle() {
        return {
            to: `${this.inControlPanelKnowledgeBase.to}/edit-article`,
        }
    }
    
    get inControlPanelKnowledgeBaseSearch() {
        return {
            to: `${this.inControlPanelKnowledgeBase.to}/search`,
        }
    }

    get myOrders() {
        return {
            to: "/my-orders",
        }
    }

    get openedOrder() {
        return {
            to: "/my-orders/order",
        }
    }

    public editOrder({orderId}: {orderId: string | number}) {
        return {
            to: `/my-orders/order/${orderId}/edit`,
        }
    }

    get printAllSamplesPage() {
        return {
            to: "/print-samples/all/order",
        }
    }

    public printSingleSample({orderId, participantId}: {orderId: string, participantId: string}) {
        return {
            to: `/print-samples/single/order/${orderId}/participant/${participantId}`,
        };
    }
}
