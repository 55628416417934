import { GetCurrentUserApiSlice } from "@/api/Users/GetCurrentUser/GetCurrentUserApiSlice";
import { RootState } from "@/store/Store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type CourierCallStep = "yesNoBtns" | "contactForm";
export const notSetCreatedOrderId = "-1";

function getInitialState() {
    return {
        courierDialogVisible: false,
        step: "yesNoBtns" as CourierCallStep,

        form: {
            createdOrderId: notSetCreatedOrderId,
            mailingAddress: "",
            contactPhone: "",
            date: "",
            time: "8-11",
        }
    }
}

export const CallCourierSlice = createSlice({
    name: "CallCourierSlice",
    initialState: getInitialState(),
    reducers: {
        changeDialogVisible: (state, action: PayloadAction<boolean>) => {
            state.courierDialogVisible = action.payload;
        },
        changeStep: (state, action: PayloadAction<CourierCallStep>) => {
            state.step = action.payload;
        },
        setFormValue: (state, action: PayloadAction<{name: string, value: string}>) => {
            const {name, value} = action.payload;
            //@ts-ignore
            state.form[name] = value;
        },
        reset: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.courierDialogVisible = initialState.courierDialogVisible;
            state.step = initialState.step;
            state.form = initialState.form;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(GetCurrentUserApiSlice.endpoints.getCurrentUser.matchFulfilled, (state, action) => {
                if (action.payload.result === "success") {
                    if (action.payload.data.partner !== null) {
                        state.form.mailingAddress = action.payload.data.partner.physical_addresses_of_partners[0].physicalAddress;
                    }

                    state.form.contactPhone = action.payload.data.tel === null ? "" : action.payload.data.tel;
                }
            })
    }
});

export const {
    changeDialogVisible,
    changeStep,
    setFormValue,
    reset,
} = CallCourierSlice.actions;

export const dialogVisibleSelector = (state: RootState) => state.callCourier.courierDialogVisible;
export const dialogStepSelector = (state: RootState) => state.callCourier.step;
export const formSelector = (state: RootState) => state.callCourier.form;

export const CallCourierReducer = CallCourierSlice.reducer;