import {createSlice} from "@reduxjs/toolkit";

export const partnerTypeNotChosenId = 0;

function getInitialState() {
    return {
        login: "",
        loginError: "",
        loginValid: true,

        partnerType: "",
        partnerTypeError: "",
        partnerTypeValid: true,

        password: "",
        passwordError: "",
        passwordValid: true,

        repeatPassword: "",
        repeatPasswordError: "",
        repeatPasswordValid: true,

        manager: "",
        managerError: "",
        managerValid: true,

        companyFull: "",
        companyFullError: "",
        companyFullValid: true,

        companyShort: "",
        companyShortError: "",
        companyShortValid: true,

        legalAddress: "",
        legalAddressError: "",
        legalAddressValid: true,

        INN: "",
        INNError: "",
        INNValid: true,

        KPP: "",
        KPPError: "",
        KPPValid: true,

        OGRN: "",
        OGRNError: "",
        OGRNValid: true,

        nameOfBank: "",
        nameOfBankError: "",
        nameOfBankValid: true,

        checkingAccount: "",
        checkingAccountError: "",
        checkingAccountValid: true,

        correspondentAccount: "",
        correspondentAccountError: "",
        correspondentAccountValid: true,

        BIK: "",
        BIKError: "",
        BIKValid: true,
    }
}

export const EditPartnerFormSlice = createSlice({
    name: "EditPartnerFormSlice",
    initialState: getInitialState(),
    reducers: {
        setValue: (state, action) => {
            const value = action.payload.value;
            const name = action.payload.name;
            //@ts-ignore
            state[name] = value;
        },
        setToDefault: (state, action) => {
            state.login = "";
            state.loginError = "";
            state.loginValid = true;

            state.manager = "";
            state.managerError = "";
            state.managerValid = true;

            state.companyFull = "";
            state.companyFullError = "";
            state.companyFullValid = true;

            state.companyShort = "";
            state.companyShortError = "";
            state.companyShortValid = true;

            state.legalAddress = "";
            state.legalAddressError = "";
            state.legalAddressValid = true;

            state.INN = "";
            state.INNError = "";
            state.INNValid = true;

            state.KPP = "";
            state.KPPError = "";
            state.KPPValid = true;

            state.OGRN = "";
            state.OGRNError = "";
            state.OGRNValid = true;

            state.nameOfBank = "";
            state.nameOfBankError = "";
            state.nameOfBankValid = true;

            state.checkingAccount = "";
            state.checkingAccountError = "";
            state.checkingAccountValid = true;

            state.correspondentAccount = "";
            state.correspondentAccountError = "";
            state.correspondentAccountValid = true;

            state.BIK = "";
            state.BIKError = "";
            state.BIKValid = true;
        }
    }
});

export const {
    setValue,
    setToDefault,
} = EditPartnerFormSlice.actions;

//@ts-ignore
export const getSelectorFor = name => state => state.inCPEditPartnerForm[name];

//@ts-ignore
export const editPartnerFormIsCorrectSelector = state => {
    const noErrors = state.inCPEditPartnerForm.loginValid &&
           state.inCPEditPartnerForm.partnerTypeValid &&
           state.inCPEditPartnerForm.managerValid &&
           state.inCPEditPartnerForm.companyFullValid &&
           state.inCPEditPartnerForm.companyShortValid &&
           state.inCPEditPartnerForm.legalAddressValid &&
           state.inCPEditPartnerForm.INNValid &&
           state.inCPEditPartnerForm.KPPValid &&
           state.inCPEditPartnerForm.OGRNValid &&
           state.inCPEditPartnerForm.nameOfBankValid &&
           state.inCPEditPartnerForm.checkingAccountValid &&
           state.inCPEditPartnerForm.correspondentAccountValid &&
           state.inCPEditPartnerForm.BIKValid;

    return noErrors;
}

export const EditPartnerFormReducer =  EditPartnerFormSlice.reducer;