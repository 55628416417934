import {ApiSlice} from "@/store/ApiSlice";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import {NewBaseResponseInterface} from "@/common/Http/Interfaces/NewBaseResponseInterface";
import {UserDataInterface} from "@/api/Auth/Login/Interfaces/UserDataInterface";
import {LoginPayloadInterface} from "@/api/Auth/Login/Interfaces/LoginPayloadInterface";
import { LoginValidationErrorsInterface } from "./Interfaces/LoginValidationErrorsInterface";

const restApiConfig = new RESTAPIConfig();

export const LoginFormApiSlice = ApiSlice.injectEndpoints({
    endpoints: builder => ({
        loginUser: builder.mutation<NewBaseResponseInterface<UserDataInterface, LoginValidationErrorsInterface>, LoginPayloadInterface>({
            query: userData => ({
                url: restApiConfig.loginUser(),
                method: "POST",
                body: userData,
            }),
            invalidatesTags: (result, error, arg) => {
                if (result === undefined) {
                    return [];
                }
                return ["CurrentUser"];
            }
        })
    })
});

export const {
    useLoginUserMutation,
} = LoginFormApiSlice;