import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "@/store/Store";
import {LoadingStatus} from "@/store/LoadingStatus";
import {getThreeThunk} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Thunks/getThreeThunk";
import {
    CategoryNodeInterface
} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Interfaces/CategoryNodeInterface";
import {
    CategoriesListItemInterface
} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Interfaces/CategoriesListItemInterface";
import {
    toggleCategoryInTrashThunk
} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Thunks/toggleCategoryInTrashThunk";
import {
    AddToCategoriesTreeCountOfArticles
} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Services/AddToCategoriesTreeCountOfArticles";

export const notSelectedCategoryId = -1;

function getInitialSlice() {
    return {
        treeLoadingStatus: LoadingStatus.idle as LoadingStatus,
        selectedCategory: notSelectedCategoryId,
        categoriesTree: [] as CategoryNodeInterface[],
        categoriesList: [] as CategoriesListItemInterface[],
        inTrashCategoryTogglingStatus: LoadingStatus.idle as LoadingStatus,
        showCategoriesInTrash: false,
    }
}

export const CategoriesTreeSlice = createSlice({
    name: "CategoriesTreeSlice",
    initialState: getInitialSlice(),
    reducers: {
        setCategoriesTree: (state, action) => {
            state.categoriesTree = action.payload.value;
        },
        setCategoriesList: (state, action) => {
            state.categoriesList = action.payload.value;
        },
        setSelectedCategory: (state, action) => {
            const category = action.payload.category as CategoryNodeInterface;
            state.selectedCategory = category.id;
        },
        setShowCategoriesInTrash: (state, action) => {
            state.showCategoriesInTrash = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(getThreeThunk.pending, (state, action) => {
            state.treeLoadingStatus = LoadingStatus.loading;
        })
        .addCase(getThreeThunk.fulfilled, (state, action) => {
            state.treeLoadingStatus = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success") {
                if (action.payload.data !== null) {
                    const countArticlesCountService = new AddToCategoriesTreeCountOfArticles({
                        categoriesTree: action.payload.data.tree,
                    });
                    state.categoriesTree = countArticlesCountService.getTreeWithCountOfArticles();
                    state.categoriesList = action.payload.data.list;
                }
            }
        })
        .addCase(getThreeThunk.rejected, (state, action) => {
            state.treeLoadingStatus = LoadingStatus.failed;
        })


        .addCase(toggleCategoryInTrashThunk.pending, (state, action) => {
            state.inTrashCategoryTogglingStatus = LoadingStatus.loading;
        })
        .addCase(toggleCategoryInTrashThunk.fulfilled, (state, action) => {
            state.inTrashCategoryTogglingStatus = LoadingStatus.succeeded;
        })
        .addCase(toggleCategoryInTrashThunk.rejected, (state, action) => {
            state.inTrashCategoryTogglingStatus = LoadingStatus.failed;
        })
    },
});

export const {
    setCategoriesTree,
    setCategoriesList,
    setSelectedCategory,
    setShowCategoriesInTrash,
} = CategoriesTreeSlice.actions;

export const selectedCategorySelector = (state: RootState) => state.inCPKnowledgeBaseCategoriesTree.selectedCategory;
export const loadingTreeStatusSelector = (state: RootState) => state.inCPKnowledgeBaseCategoriesTree.treeLoadingStatus;
export const categoriesTreeSelector = (state: RootState) => state.inCPKnowledgeBaseCategoriesTree.categoriesTree;
export const categoriesListSelector = (state: RootState) => state.inCPKnowledgeBaseCategoriesTree.categoriesList;
export const inTrashCategoryTogglingStatusSelector = (state: RootState) => state.inCPKnowledgeBaseCategoriesTree.inTrashCategoryTogglingStatus;
export const showCategoriesInTrashSelector = (state: RootState) => state.inCPKnowledgeBaseCategoriesTree.showCategoriesInTrash;

export const CategoriesTreeReducer = CategoriesTreeSlice.reducer;