import {createSlice} from "@reduxjs/toolkit";
import {TestTypeRowInterface} from "@/features/DNAOrders/Interfaces/TestTypeRowInterface";
import {RootState} from "@/store/Store";
import type { PayloadAction } from '@reduxjs/toolkit'
import {StatusUnitInterface} from "@/features/DNAOrders/Interfaces/StatusUnitInterface";

function getInitialState() {
    return {
        rowsInTable: 25,
        dateSorting: "notChosen",

        orderedTestTypes: [] as TestTypeRowInterface[],
        testTypeAlphabetSorting: "notChosen",

        selectedStatuses: [] as StatusUnitInterface[],

        unreadMessagesFilter: "notChosen",

        page: 1,
    };
}

export const DNAOrdersFiltersAndSortersSlice = createSlice({
    name: "DNAOrdersFiltersAndSortersSlice",
    initialState: getInitialState(),
    reducers: {
        setRowsInTable: (state, action: PayloadAction<rowsInTableVariantsValues>) => {
            state.rowsInTable = action.payload;
        },

        addOrderedTestType: (state, action: PayloadAction<TestTypeRowInterface>) => {
            state.orderedTestTypes.push(action.payload);
        },
        removeOrderedTestType: (state, action: PayloadAction<TestTypeRowInterface>) => {
            state.orderedTestTypes = state.orderedTestTypes.filter(type => type.alias !== action.payload.alias);
        },
        clearOrderedTestTypes: (state, action) => {
            state.orderedTestTypes = [];
        },
        setOrderedTestTypes: (state, action: PayloadAction<TestTypeRowInterface[]>) => {
            state.orderedTestTypes = action.payload;
        },

        setDateSorting: (state, action: PayloadAction<string>) => {
            state.dateSorting = action.payload;
        },

        setTestTypeAlphabetSorting: (state, action: PayloadAction<string>) => {
            state.testTypeAlphabetSorting = action.payload;
        },

        addStatus: (state, action: PayloadAction<StatusUnitInterface>) => {
            state.selectedStatuses.push(action.payload);
        },
        removeStatus: (state, action: PayloadAction<StatusUnitInterface>) => {
            state.selectedStatuses = state.selectedStatuses.filter(status => status.alias !== action.payload.alias);
        },
        clearStatuses: (state, action) => {
            state.selectedStatuses = [];
        },
        setStatuses: (state, action: PayloadAction<StatusUnitInterface[]>) => {
            state.selectedStatuses = action.payload;
        },
        setUnreadMessagesFilter: (state, action: PayloadAction<string>) => {
            state.unreadMessagesFilter = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
    }
});

export const {
    setRowsInTable,

    addOrderedTestType,
    removeOrderedTestType,
    clearOrderedTestTypes,
    setOrderedTestTypes,

    setDateSorting,

    setTestTypeAlphabetSorting,

    addStatus,
    removeStatus,
    clearStatuses,
    setStatuses,

    setUnreadMessagesFilter,

    setPage,
} = DNAOrdersFiltersAndSortersSlice.actions;

export const orderedTestTypesSelector = (state: RootState) => state.dnaOrdersFiltersAndSorters.orderedTestTypes;
export const rowsInTableSelector = (state: RootState) => state.dnaOrdersFiltersAndSorters.rowsInTable;
export const dateSortingSelector = (state: RootState) => state.dnaOrdersFiltersAndSorters.dateSorting;
export const testTypeAlphabetSortingSelector = (state: RootState) => state.dnaOrdersFiltersAndSorters.testTypeAlphabetSorting;
export const selectedStatusesSelector = (state: RootState) => state.dnaOrdersFiltersAndSorters.selectedStatuses;
export const unreadMessagesSelector = (state: RootState) => state.dnaOrdersFiltersAndSorters.unreadMessagesFilter;
export const pageSelector = (state: RootState) => state.dnaOrdersFiltersAndSorters.page;

export const DNAOrdersFiltersAndSortersReducer = DNAOrdersFiltersAndSortersSlice.reducer;

export const rowsInTableVariants = {
    "25": 25,
    "50": 50,
    "100": 100,
} as const;
type rowsInTableVariantsKeys = keyof typeof rowsInTableVariants;
export type rowsInTableVariantsValues = (typeof rowsInTableVariants)[rowsInTableVariantsKeys];


