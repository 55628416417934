import { RootState } from "@/store/Store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

function getInitialState() {
    return {
        fields: {
            subject: "",
            text: "",
        }
    };
}

export const CreateAppealFormSlice = createSlice({
    name: "CreateAppealFormSlice",
    initialState: getInitialState(),
    reducers: {
        setFieldValue: (state, action: PayloadAction<{name: string, value: string,}>) => {
            const {name, value} = action.payload;
            //@ts-ignore
            state.fields[name] = value;
        },
    },
});

export const {
    setFieldValue,
} = CreateAppealFormSlice.actions;

export const fieldsSelector = (state: RootState) => state.appealsCreateForm.fields;

export const CreateAppealFormReducer = CreateAppealFormSlice.reducer;