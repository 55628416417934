import {
    CategoryNodeInterface
} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Interfaces/CategoryNodeInterface";

class AddToCategoriesTreeCountOfArticles {
    private readonly categoriesTree: CategoryNodeInterface[];

    constructor(
        {categoriesTree}:
        {categoriesTree: CategoryNodeInterface[]}
    ) {
        this.categoriesTree = categoriesTree;
    }

    public getTreeWithCountOfArticles() {

        this.categoriesTree.forEach(category => {
            this.countArticlesCountInDirectChildren(category);
        })

        return this.categoriesTree;
    }

    private countArticlesCountInDirectChildren(category: CategoryNodeInterface) {
        let count = category.articles_count;
        if (Array.isArray(category.children) && category.children.length > 0) {
            category.children.forEach(child => {
                count += this.countArticlesCountInDirectChildren(child);
            });
        }
        category.articlesCountInCurrentCategoryAndSubCategories = count;
        return count;
    }
}

export {AddToCategoriesTreeCountOfArticles}