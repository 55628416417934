import { CurrentUserConfig } from "@/features/Auth/CurrentUser/Config/CurrentUserConfig";

class AccessTokenStorageService {
    public getTokenFromStorage() {

        try {
            const token = localStorage.getItem(CurrentUserConfig.storageTokenKeyName);
            if (token === null) {
                return "";
            }
            return token;
        } catch(e: unknown) {
            return "";
        }
        
    }

    public setTokenToStorage({ token }: { token: string }) {
        localStorage.setItem(CurrentUserConfig.storageTokenKeyName, token);
    }

    public removeTokenFromStorage() {
        localStorage.removeItem(CurrentUserConfig.storageTokenKeyName);
    }
}

export { AccessTokenStorageService }