import {createSlice} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import {OpenedArticleDataInterface} from "@/features/KnowledgeBase/OpenedArticle/Interfaces/OpenedArticleDataInterface";
import {getArticleThunk} from "@/features/KnowledgeBase/OpenedArticle/Thunks/getArticleThunk";
import {RootState} from "@/store/Store";

function getInitialState() {
    return {
        articleLoadingStatus: LoadingStatus.idle as LoadingStatus,

        articleData: {
            id: "",
            title: "",
            fullText: "",
            updated_at: "",
            previewUrl: null,
        } as OpenedArticleDataInterface,
    }
}

export const OpenedArticleSlice = createSlice({
    name: "OpenedArticleSlice",
    initialState: getInitialState(),
    reducers: {

    },

    extraReducers(builder) {
        builder
        .addCase(getArticleThunk.pending, (state, action) => {
            state.articleLoadingStatus = LoadingStatus.loading;
        })
        .addCase(getArticleThunk.fulfilled, (state, action) => {
            state.articleLoadingStatus = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success") {
                state.articleData = action.payload.data as OpenedArticleDataInterface;
            }
        })
        .addCase(getArticleThunk.rejected, (state, action) => {
            state.articleLoadingStatus = LoadingStatus.failed;
        })
    }
});

export const articleDataSelector = (state: RootState) => state.openedArticleOfKnowledgeBase.articleData;

export const OpenedArticleReducer = OpenedArticleSlice.reducer;