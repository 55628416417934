import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    form: {
        login: "",
        password: "",
    },
    validation: {
        login: false,
        password: false,
    },
    errors: {
        login: "",
        password: "",
    }
};

export const LoginFormSlice = createSlice({
    name: "LoginFormSlice",
    initialState,
    reducers: {
        setFormValue: (state, action) => {
            //@ts-ignore
            state.form[action.payload.name] = action.payload.value;
        },
        setErrorValue: (state, action) => {
            //@ts-ignore
            state.errors[action.payload.name] = action.payload.value;
        },
        setValidationValue: (state, action) => {
            //@ts-ignore
            state.validation[action.payload.name] = action.payload.value;
        },
        clearAll: (state, action) => {
            state.form = initialState.form;
            state.validation = initialState.validation;
            state.errors = initialState.errors;
        },
    }
});

export const {
    setFormValue,
    setErrorValue,
    setValidationValue,
    clearAll,
} = LoginFormSlice.actions;

//@ts-ignore
export const getSelectorFormFor = name => state => state.loginForm.form[name];
//@ts-ignore
export const getSelectorErrorFor = name => state => state.loginForm.errors[name];
//@ts-ignore
export const getSelectorValidationFor = name => state => state.loginForm.validation[name];

export const LoginFormReducer = LoginFormSlice.reducer;