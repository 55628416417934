import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    contacts: [] as ContactInterface[],
    formStage: "new",
    form: {
        id: "",
        role: "choose",
        fio: "",
        phone: "",
        email: "",
        position: "",
        note: "",
    },
    formErrors: {
        role: "",
        fio: "",
        phone: "",
        email: "",
        position: "",
        note: "",
    },
    validation: {
        role: false,
        fio: false,
        phone: false,
        email: false,
        position: true,
        note: true,
    }
}

export const EditPartnerFormContactsSlice = createSlice({
    name: "EditPartnerFormContactsSlice",
    initialState,
    reducers: {
        setContacts: (state, action) => {
            state.contacts = action.payload;
        },
        setFormUnit: (state, action) => {
            //@ts-ignore
            state.form[action.payload.name] = action.payload.value;
        },
        setFormError: (state, action) => {
            //@ts-ignore
            state.formErrors[action.payload.name] = action.payload.value;
        },
        setFormValidation: (state, action) => {
            //@ts-ignore
            state.validation[action.payload.name] = action.payload.value;
        },
        addContact: (state, action: PayloadAction<ContactInterface>) => {
            state.contacts.push(action.payload);
        },
        editContact: (state, action: PayloadAction<ContactInterface>) => {
            console.log('action payload', action.payload);
            state.contacts = state.contacts.map(contact => {
                if (action.payload.id === contact.id) {
                    return action.payload;
                }
                return contact;
            })
        },
        deleteContact: (state, action) => {
            state.contacts = state.contacts.filter(contact => {
                return contact.id !== action.payload;
            });
        },
        setFormToDefault: (state, action) => {
            state.form = {
                id: "",
                role: "choose",
                fio: "",
                phone: "",
                email: "",
                position: "",
                note: "",
            };
        },
        setValidationToDefault: (state, action) => {
            state.validation = {
                role: false,
                fio: false,
                phone: false,
                email: false,
                position: true,
                note: true,
            };
        },
        setValidationToValid: (state, action) => {
            state.validation = {
                role: true,
                fio: true,
                phone: true,
                email: true,
                position: true,
                note: true,
            };
        },
        setFormStage: (state, action) => {
            state.formStage = action.payload;
        },
        setContactForm: (state, action: PayloadAction<ContactInterface>) => {
            state.form.email = action.payload.email ?? "";
            state.form.fio = action.payload.fio ?? "";
            state.form.id = action.payload.id;
            state.form.note = action.payload.note ?? "";
            state.form.phone = action.payload.phone ?? "";
            state.form.position = action.payload.position ?? "";
            state.form.role = `${action.payload.contact_role.id}`;
        },
        setContactsListToEmpty: (state, action) => {
            state.contacts = [];
        },
    }
});

export const {
    setContacts,
    setFormUnit,
    setFormError,
    setFormValidation,
    addContact,
    editContact,
    deleteContact,
    setFormToDefault,
    setValidationToDefault,
    setFormStage,
    setContactForm,
    setValidationToValid,
    setContactsListToEmpty,
} = EditPartnerFormContactsSlice.actions;

//@ts-ignore
export const roleSelector = state => state.inCPEditPartnerFormContacts.form.role;
//@ts-ignore
export const formSelector = state => state.inCPEditPartnerFormContacts.form;
//@ts-ignore
export const formErrorsSelector = state => state.inCPEditPartnerFormContacts.formErrors;
//@ts-ignore
export const contactsSelector = state => state.inCPEditPartnerFormContacts.contacts;
//@ts-ignore
export const formStageSelector = state => state.inCPEditPartnerFormContacts.formStage;

//@ts-ignore
export const contactsAreCorrectSelector = state => {
    return state.inCPEditPartnerFormContacts.validation.role &&
           state.inCPEditPartnerFormContacts.validation.fio &&
           state.inCPEditPartnerFormContacts.validation.phone &&
           state.inCPEditPartnerFormContacts.validation.email &&
           state.inCPEditPartnerFormContacts.validation.position &&
           state.inCPEditPartnerFormContacts.validation.note;
};

export const EditPartnerFormContactsReducer = EditPartnerFormContactsSlice.reducer;

export interface ContactInterface {
    id: string,
    contact_role: ContactRole,
    fio?: string,
    phone?: string,
    email?: string,
    position?: string,
    note?: string,
}

export interface ContactRole {
    alias: string,
    id: number,
    title: string,
}

export const FormStage = {
    new: "new",
    edit: "edit",
}