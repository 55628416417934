import {createSlice} from '@reduxjs/toolkit';
import {minWidthForDesktopLeftMenu} from "@/components/Menu/LeftMenu/LeftMenu";

const UILeftMenuSlice = createSlice({
    name: "UILeftMenuSlice",
    initialState: {
        opened: window.innerWidth >= minWidthForDesktopLeftMenu,
    },
    reducers: {
        toggle: state => {
            state.opened = ! state.opened;
        },
        show: state => {
            state.opened = true;
        },
        hide: state => {
            state.opened = false;
        }
    }
});

export const {toggle, show, hide} = UILeftMenuSlice.actions;

//@ts-ignore
export const leftMenuOpenedSelector = state => state.uiLeftMenu.opened;

export const UILeftMenuReducer = UILeftMenuSlice.reducer;