import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "@/store/Store";

function getInitialState() {
    return {
        rowsInTable: 25,
        page: 1,
    };
}

export const ApplicationsListSlice = createSlice({
    name: "ApplicationsListSlice",
    initialState: getInitialState(),
    reducers: {
        setRowsInTable: (state, action) => {
            state.rowsInTable = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
    },
});

export const {
    setRowsInTable,
    setPage,
} = ApplicationsListSlice.actions;

//@ts-ignore
export const getSelectorFor = (name: string) => (state: RootState) => state.inCPApplicationsList[name];

export const ApplicationsListReducer = ApplicationsListSlice.reducer;

export const rowsInTableVariants = {
    "25": 25,
    "50": 50,
    "100": 100,
} as const;
type rowsInTableVariantsKeys = keyof typeof rowsInTableVariants;
export type rowsInTableVariantsValues = (typeof rowsInTableVariants)[rowsInTableVariantsKeys];