import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {RootState} from "@/store/Store";
import { DeletingUserStateInterface } from "@/features/InControlPanel/Users/UsersList/Interfaces/DeletingUserStateInterface";

function getInitialState() {
    return {
        user: {},

        selectedSeniorManagerId: "",
        selectedManagerId: "",

        whatToDoWithOrdersOfCurrentPartner: "",
        selectedPartnerIdForOrders: "",
    } as DeletingUserStateInterface;
}

export const DeletingUserSlice = createSlice({
    name: "DeletingUserSlice",
    initialState: getInitialState(),
    reducers: {
        setDeletingUser: (state, action) => {
            state.user = action.payload;
        },
        setSelectedSeniorManagerId: (state, action) => {
            state.selectedSeniorManagerId = action.payload;
        },
        setSelectedManagerId: (state, action) => {
            state.selectedManagerId = action.payload;
        },
        setSelectedPartnerIdForOrders: (state, action) => {
            state.selectedPartnerIdForOrders = action.payload;
        },
        reset: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.user = initialState.user;
            state.selectedSeniorManagerId = initialState.selectedSeniorManagerId;
            state.selectedManagerId = initialState.selectedManagerId;
            state.whatToDoWithOrdersOfCurrentPartner = initialState.whatToDoWithOrdersOfCurrentPartner;
            state.selectedPartnerIdForOrders = initialState.selectedPartnerIdForOrders;
        },
        setWhatToDoWithOrders: (state, action) => {
            state.whatToDoWithOrdersOfCurrentPartner = action.payload;
        },
    },
});

export const {
    setDeletingUser,
    setSelectedSeniorManagerId,
    reset,
    setSelectedManagerId,
    setWhatToDoWithOrders,
    setSelectedPartnerIdForOrders,
} = DeletingUserSlice.actions;

//@ts-ignore
export const getSelectorFor = (name: string) => (state: RootState) => state.inCPDeletingUser[name];
export const deletingUserStateSelector = (state: RootState) => state.inCPDeletingUser;

export const DeletingUserReducer = DeletingUserSlice.reducer;