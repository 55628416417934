import RESTAPIConfig from "@/config/RESTAPIConfig";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {showPopup} from "@/features/PopupMessages/PopupMessages";
import {LoadingStatus} from "@/store/LoadingStatus";
import {TestTypeInterface} from "@/interfaces/EntitiesFromApi/TestTypeInterface";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import axios, {AxiosError} from "axios";
import configuredStore, {RootState} from "@/store/Store";
import i18n from "@/i18n/i18n";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";

const restApiConfig = new RESTAPIConfig();

/**
 * @deprecated
 */
export const fetchAllTestTypesThunk = createAsyncThunk(
    "allTestTypes/fetchData",
    async () => {
        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getAllTestTypes();
        const userLang = configuredStore.getState().lang.userLang;
        const token = getAccessToken(configuredStore.getState());

        try {
            const response = await axios.get<JsonResponseInterface<TestTypeInterface[]>>(
                url,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                        [userLangHeaderName]: userLang,
                        "Authorization": `Bearer ${token}`,
                    }
                }
            )

            if (response.data.data === null) {
                showPopup({
                    type: "error",
                    message: response.data.errors.toString(),
                    autoClose: 5000,
                });
                return;
            }

            configuredStore.dispatch(setTestTypes(response.data.data));

        } catch (e: unknown) {
            const error = e as AxiosError;
            // @ts-ignore
            const message = i18n.t("Error with code and text", {code: error.response.status, error: error.response.data.errors.toString()});
            showPopup({
                type: "error",
                message,
                autoClose: 5000,
            })
        }
    }
)

function getInitialState() {
    return {
        loadingStatus: "idle" as LoadingStatus,
        testTypes: null as TestTypeInterface[] | null,
    }
}

export const AllTestTypesSlice = createSlice({
    name: "AllTestTypesApiSlice",
    initialState: getInitialState(),
    reducers: {
        setTestTypes: (state, action: PayloadAction<TestTypeInterface[]>) => {
            state.testTypes = action.payload;
        }
    },

    extraReducers(builder) {
        builder
        .addCase(fetchAllTestTypesThunk.pending, (state, actiion) => {
            state.loadingStatus = LoadingStatus.loading;
        })
        .addCase(fetchAllTestTypesThunk.fulfilled, (state, actiion) => {
            state.loadingStatus = LoadingStatus.succeeded;
        })
        .addCase(fetchAllTestTypesThunk.rejected, (state, actiion) => {
            state.loadingStatus = LoadingStatus.failed;
        })
    }
});

const {setTestTypes} = AllTestTypesSlice.actions;
export const loadingStatusSelector = (state: RootState) => state.allTestTypes.loadingStatus;
export const testTypesSelector = (state: RootState) => state.allTestTypes.testTypes;

export const AllTestTypesReducer = AllTestTypesSlice.reducer;
