import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "@/store/Store";
import {getLangFromStorage} from "@/i18n/i18n";

function getInitialState() {
    return {
        userLang: getLangFromStorage(),
    }
}

export const LangSlice = createSlice({
    name: "LangSlice",
    initialState: getInitialState(),
    reducers: {
        setLang: (state, action) => {
            state.userLang = action.payload;
        }
    }
});

export const {
    setLang,
} = LangSlice.actions;

export const userLangSelector = (state: RootState) => state.lang.userLang;

export const LangReducer = LangSlice.reducer;