import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import configuredStore, {RootState} from "@/store/Store";
import {BreadcrumbInterface} from "@/features/KnowledgeBase/KnowledgeBaseBreadcrumbs/Interfaces/BreadcrumbInterface";
import UrlsConfig from "@/config/UrlsConfig";
import i18n from "@/i18n/i18n";

import i18next from "i18next";

i18next.on('languageChanged', function(lng) {
    configuredStore.dispatch(CurrentCategorySlice.actions.switchLanguages(undefined));
});

const urlsConfig = new UrlsConfig();

export function getInitialState() {
    return {
        currentCategoryId: null as null | number,
        breadcrumbs: [
            {
                id: null,
                title: i18n.t("Index"),
                to: urlsConfig.index.to,
            },
            {
                id: null,
                title: i18n.t("Knowledge base"),
                to: urlsConfig.knowledgeBase.to,
            },
        ] as BreadcrumbInterface[],
    }
}

export const CurrentCategorySlice = createSlice({
    name: "CurrentCategorySlice",
    initialState: getInitialState(),
    reducers: {
        setCurrentCategory: (state, action: PayloadAction<number|null>) => {
            state.currentCategoryId = action.payload;
        },
        pushBreadcrumb: (state, action: PayloadAction<BreadcrumbInterface>) => {
            state.breadcrumbs.push(action.payload);
        },
        changeBreadcrumbs: (state, action: PayloadAction<BreadcrumbInterface[]>) => {
            state.breadcrumbs = action.payload;
        },
        resetBreadcrumbs: (state, action) => {
            const initialState = getInitialState();
            state.breadcrumbs = initialState.breadcrumbs;
        },
        switchLanguages: (state, action) => {
            const initialState = getInitialState();
            state.breadcrumbs[0] = initialState.breadcrumbs[0];
            state.breadcrumbs[1] = initialState.breadcrumbs[1];
        },
    },
});

export const {
    setCurrentCategory,
    pushBreadcrumb,
    changeBreadcrumbs,
    resetBreadcrumbs,
} = CurrentCategorySlice.actions;

export const currentCategoryIdSelector = (state: RootState) => state.currentCategoryOfKnowledgeBase.currentCategoryId;
export const categoriesBreadcrumbsSelector = (state: RootState) => state.currentCategoryOfKnowledgeBase.breadcrumbs;

export const CurrentCategoryReducer = CurrentCategorySlice.reducer;