import {createAsyncThunk} from "@reduxjs/toolkit";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios, {AxiosError} from "axios";
import {showPopup} from "@/features/PopupMessages/PopupMessages";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import i18n from "@/i18n/i18n";
import configuredStore from "@/store/Store";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";
import {
    ArticleShortInfoInterface
} from "@/features/InControlPanel/KnowledgeBase/ArticlesOfCategory/Interfaces/ArticleShortInfoInterface";

const restApiConfig = new RESTAPIConfig();

export const fetchArticleThunk = createAsyncThunk(
    "editArticleForm/fetchArticle",
    async ({articleId}: {articleId: string}) => {
        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getKnowledgeBaseGetArticle({articleId});
        let response;
        const userLang = configuredStore.getState().lang.userLang;
        const token = getAccessToken(configuredStore.getState());
        try {
            response = await axios.get<JsonResponseInterface<ArticleShortInfoInterface>>(url, {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    [userLangHeaderName]: userLang,
                    "Authorization": `Bearer ${token}`,
                }
            });

            return response.data;

        } catch (e: unknown) {
            const error = e as AxiosError;
            //@ts-ignore
            const responseStatus = error.response.status;
            const message = i18n.t("Error with text", {error: responseStatus});
            showPopup({
                type: "error",
                message,
                autoClose: 5000,
            });

            //@ts-ignore
            return e.response.data;
        }
    },
)