import "./styles/main.scss";
import { ThemeProvider } from '@mui/material/styles';
import { InLabB2BTheme } from "@/theme/Inlabb2b";
import { Provider } from 'react-redux';
import Store from "@/store/Store";
import CssBaseline from "@mui/material/CssBaseline";
import { AppRoutes } from "./routes/AppRoutes";
import ErrorsCatcher from "./components/ErrorsCatcher/Tsx/ErrorsCatcher";
import ShowGlobalError from "./components/ShowGlobalError/Tsx/ShowGlobalError";

function App() {

    return (
        <Provider store={Store}>
            <ThemeProvider theme={InLabB2BTheme}>
                <CssBaseline />
                <ShowGlobalError />

                <ErrorsCatcher errorId="8f6d6238-eed0-46f5-b191-e286b262d75c">
                    <AppRoutes />
                </ErrorsCatcher>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
