import {createAsyncThunk} from "@reduxjs/toolkit";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios, {AxiosError} from "axios";
import {showPopup} from "@/features/PopupMessages/PopupMessages";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import configuredStore from "@/store/Store";
import {docsToUpload} from "@/features/InControlPanel/DNAOrderContent/Services/DocumentsToUpload";
import i18n from "@/i18n/i18n";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";

const restApiConfig = new RESTAPIConfig();

export const uploadFilesThunk = createAsyncThunk(
    "openedDNAOrder/uploadFiles",
    async ({orderNum}: {orderNum: string}) => {
        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getDNAOrderUploadFilesInControlPanel({orderId: orderNum});

        try {
            const uploadingForm = configuredStore.getState().labDocumentsUploading;
            let formData = new FormData();
            const filesMetadata = [];

            for (const file of uploadingForm.files) {
                if (file.file !== null) {
                    const fileName = docsToUpload.getFileById({id: file.id}).name;
                    filesMetadata.push({
                        fileName,
                        id: file.id,
                        meaning: file.meaning,
                    });

                    formData.append("files", docsToUpload.getFileById({id: file.id}));
                }
            }

            if (filesMetadata.length > 0) {
                formData.append("filesMetadataJson", JSON.stringify(filesMetadata));
            }

            const userLang = configuredStore.getState().lang.userLang;
            const token = getAccessToken(configuredStore.getState());

            const response = await axios.post<JsonResponseInterface<null>>(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    [userLangHeaderName]: userLang,
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (response.data.result === "success") {
                showPopup({
                    type: "success",
                    message: i18n.t("The files were successfully uploaded"),
                    autoClose: 5000,
                });
            }

            return response.data;

        } catch (e: unknown) {
            const error = e as AxiosError;
            //@ts-ignore
            const message = i18n.t("Error with code and text", {code: error.response.status, error: error.response.data.errors.toString()});
            showPopup({
                type: "error",
                message,
                autoClose: 5000,
            });
        }
    }
)