import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import {RootState} from "@/store/Store";
import {fetchOrderDataThunk} from "@/features/InControlPanel/DNAOrderContent/Thunks/fetchOrderDataThunk";
import {uploadFilesThunk} from "@/features/InControlPanel/DNAOrderContent/Thunks/uploadFilesThunk";
import {deleteFileThunk} from "@/features/InControlPanel/DNAOrderContent/Thunks/deleteFileThunk";
import {OrderInterface} from "@/features/InControlPanel/AllOrdersToSeeByStaff/Interfaces/OrderInterface";

function getInitialState() {
    return {
        loadingStatus: "idle" as LoadingStatus,
        order: null as OrderInterface | null,
    }
}

export const InCPOpenedDNAOrderSlice = createSlice({
    name: "InCPOpenedDNAOrderSlice",
    initialState: getInitialState(),
    reducers: {
        setOrder: (state, action: PayloadAction<OrderInterface>) => {
            state.order = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(fetchOrderDataThunk.pending, (state, action) => {
            state.loadingStatus = LoadingStatus.loading;
        })
        .addCase(fetchOrderDataThunk.fulfilled, (state, action) => {
            state.loadingStatus = LoadingStatus.succeeded;
        })
        .addCase(fetchOrderDataThunk.rejected, (state, action) => {
            state.loadingStatus = LoadingStatus.failed;
        })

        .addCase(uploadFilesThunk.fulfilled, (state, action) => {
            if (action.payload === undefined) {
                return;
            }

            if (action.payload.data === null) {
                return
            }

            if ( ! ("labFiles" in action.payload.data) ) {
                return;
            }

            if (state.order === null) {
                return;
            }

            //@ts-ignore
            state.order.labFiles = action.payload.data.labFiles;
        })

        .addCase(deleteFileThunk.fulfilled, (state, action) => {
            if (action.payload === undefined) {
                return;
            }

            if (action.payload.data === null) {
                return
            }

            if ( ! ("labFiles" in action.payload.data) ) {
                return;
            }

            if (state.order === null) {
                return;
            }

            //@ts-ignore
            state.order.labFiles = action.payload.data.labFiles;
        })
    }
});

export const {setOrder} = InCPOpenedDNAOrderSlice.actions;
export const loadingStatusSelector = (state: RootState) => state.inCPOpenedDNAOrder.loadingStatus;
export const openedDNAOrderSelector = (state: RootState) => state.inCPOpenedDNAOrder.order;

export const InCPOpenedDNAOrderReducer = InCPOpenedDNAOrderSlice.reducer;