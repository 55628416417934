import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserRoleInterface} from "@/features/InControlPanel/Users/CreateUser/Interfaces/UserRoleInterface";
import {RootState} from "@/store/Store";

function getInitialState() {
    return {
        selectedRolesFilter: [] as UserRoleInterface[],

        registrationDateSorter: "" as "" | "newFirst" | "oldFirst",
        fioOrLegalNameSorter: "" as "" | "fromAtoZ" | "fromZtoA",
        emailSorter: "" as "" | "fromAtoZ" | "fromZtoA",

        registrationDateFrom: "",
        registrationDateTo: "",

        fioOrLegalNameSearch: "",
        byEmailSearch: "",

        rowsInTable: 25,
        page: 1,

        deleteUserDialogIsOpen: false,
    }
}

export const UsersListSlice = createSlice({
    name: "UsersListSlice",
    initialState: getInitialState(),
    reducers: {
        addSelectedRole: (state, action) => {
            state.selectedRolesFilter.push(action.payload);
        },
        removeSelectedRole: (state, action) => {
            state.selectedRolesFilter = state.selectedRolesFilter.filter(role => role.alias !== action.payload.alias);
        },
        clearSelectedRoles: (state, action) => {
            const initialState = getInitialState();
            state.selectedRolesFilter = initialState.selectedRolesFilter;
        },
        setSelectedRoles: (state, action: PayloadAction<UserRoleInterface[]>) => {
            state.selectedRolesFilter = action.payload;
        },

        setRegistrationDateSorter: (state, action) => {
            state.registrationDateSorter = action.payload;
        },
        setFioOrLegalNameSorter: (state, action) => {
            state.fioOrLegalNameSorter = action.payload;
        },
        setEmailSorter: (state, action) => {
            state.emailSorter = action.payload;
        },

        setRegistrationDateFrom: (state, action) => {
            state.registrationDateFrom = action.payload;
        },
        setRegistrationDateTo: (state, action) => {
            state.registrationDateTo = action.payload;
        },

        setFioOrLegalNameSearch: (state, action) => {
            state.fioOrLegalNameSearch = action.payload;
        },
        setByEmailSearch: (state, action) => {
            state.byEmailSearch = action.payload;
        },
        setRowsInTable: (state, action) => {
            state.rowsInTable = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },

        setDeleteUserDialogIsOpen: (state, action) => {
            state.deleteUserDialogIsOpen = action.payload;
        },
    }
});

export const {
    addSelectedRole,
    removeSelectedRole,
    clearSelectedRoles,
    setSelectedRoles,
    setRegistrationDateSorter,
    setFioOrLegalNameSorter,
    setEmailSorter,
    setFioOrLegalNameSearch,
    setByEmailSearch,
    setRegistrationDateFrom,
    setRegistrationDateTo,
    setRowsInTable,
    setPage,
    setDeleteUserDialogIsOpen,
} = UsersListSlice.actions;

//@ts-ignore
export const getSelectorFor = (name: string) => (state: RootState) => state.inCPUsersList[name];

export const UsersListReducer = UsersListSlice.reducer;

export const rowsInTableVariants = {
    "25": 25,
    "50": 50,
    "100": 100,
} as const;
type rowsInTableVariantsKeys = keyof typeof rowsInTableVariants;
export type rowsInTableVariantsValues = (typeof rowsInTableVariants)[rowsInTableVariantsKeys];