import {ToastContainer, toast, ToastOptions} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import type { ToastItem } from "react-toastify";

const PopupMessages = () => {

    return (
            <ToastContainer
            position="bottom-right"
            autoClose={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="dark"
        />
    )
}

export interface MessageDataInterface {
    type: "error" | "warning" | "info" | "success";
    message: string;
    autoClose?: number | false;
    popupRemovedCb?: () => void;
};

export function showPopup({
      type, message, autoClose = false, popupRemovedCb = () => {}
    }: MessageDataInterface
) {

    const data = {
        position: "bottom-right",
        autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    } as ToastOptions;

    toast.onChange((payload: ToastItem) => {
        switch (payload.status) {
            case "removed":
                popupRemovedCb();
                break;
        }
    })

    switch (type) {
        case "success":
            toast.success(message, data);
            break;
        case "warning":
            toast.warn(message, data);
            break;
        case "info":
            toast.info(message, data);
            break;
        case "error":
            toast.error(message, data);
            break;
    }
}

export {PopupMessages}