import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    addresses: [] as AddressInterface[],
    formStage: "new",
    form: {
        id: "",
        addressField: "",
    }
};

export const PhysicalAddressSlice = createSlice({
    name: "PhysicalAddressSlice",
    initialState,
    reducers: {
        setAddressField: (state, action) => {
            state.form.addressField = action.payload;
        },
        addAddress: (state, action) => {
            state.addresses.push({id: uuidv4(), address: action.payload});
            state.form.id = "";
            state.form.addressField = "";
        },
        editAddress: (state) => {
            const editingAddress = state.addresses.find(address => address.id === state.form.id) as AddressInterface;
            editingAddress.address = state.form.addressField;
            state.formStage = FormStage.new;
            state.form.id = "";
            state.form.addressField = "";
        },
        deleteAddress: (state, action) => {
            const deletingAddressId = action.payload.id;
            state.addresses = state.addresses.filter(address => address.id !== deletingAddressId);
            state.formStage = FormStage.new;
            state.form.id = "";
            state.form.addressField = "";
        },
        setAddressForm: (state, action) => {
            state.form.id = action.payload.id;
            state.form.addressField = action.payload.addressField;
        },
        setFormStage: (state, action) => {
            state.formStage = action.payload;
        },
        setToDefault: (state, action) => {
            state.addresses = [];
            state.formStage = FormStage.new;
            state.form.id = "";
            state.form.addressField = "";
        },
    }
});

export const {
    setAddressField,
    addAddress,
    editAddress,
    setAddressForm,
    setFormStage,
    deleteAddress,
    setToDefault,
} = PhysicalAddressSlice.actions;

//@ts-ignore
export const addressesSelector = state => state.registerNewPartnerPhysicalAddress.addresses;
//@ts-ignore
export const addressFieldSelector = state => state.registerNewPartnerPhysicalAddress.form.addressField;
//@ts-ignore
export const formStageSelector = state => state.registerNewPartnerPhysicalAddress.formStage;
//@ts-ignore
export const physicalAddressesAreCorrectSelector = state => state.registerNewPartnerPhysicalAddress.addresses.length > 0;

export const RegisterNewPartnerPhysicalAddressReducer = PhysicalAddressSlice.reducer;

export interface AddressInterface {
    id: string,
    address: string,
}

export const FormStage = {
    new: "new",
    edit: "edit",
}