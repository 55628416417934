import {createSlice} from "@reduxjs/toolkit";
import {TestTypeRowInterface} from "@/features/DNAOrders/Interfaces/TestTypeRowInterface";
import {RootState} from "@/store/Store";
import type { PayloadAction } from '@reduxjs/toolkit'
import {StatusUnitInterface} from "@/features/DNAOrders/Interfaces/StatusUnitInterface";
import {rowsInTableVariantsValues} from "@/features/DNAOrders/Slice/DNAOrdersFiltersAndSortersSlice";

function getInitialState() {
    return {
        rowsInTable: 25,
        dateSorting: "notChosen",

        selectedTestTypes: [] as TestTypeRowInterface[],
        testTypeAlphabetSorting: "notChosen",

        selectedStatuses: [] as StatusUnitInterface[],

        page: 1,
    };
}

export const AllOrdersFiltersAndSortersSlice = createSlice({
    name: "AllOrdersFiltersAndSortersSlice",
    initialState: getInitialState(),
    reducers: {
        setRowsInTable: (state, action: PayloadAction<rowsInTableVariantsValues>) => {
            state.rowsInTable = action.payload;
        },

        selectTestType: (state, action: PayloadAction<TestTypeRowInterface>) => {
            state.selectedTestTypes.push(action.payload);
        },
        removeSelectedTestType: (state, action: PayloadAction<TestTypeRowInterface>) => {
            state.selectedTestTypes = state.selectedTestTypes.filter(type => type.alias !== action.payload.alias);
        },
        clearSelectedTestTypes: (state, action) => {
            state.selectedTestTypes = [];
        },
        setTestTypes: (state, action: PayloadAction<TestTypeRowInterface[]>) => {
            state.selectedTestTypes = action.payload;
        },

        setDateSorting: (state, action: PayloadAction<string>) => {
            state.dateSorting = action.payload;
        },

        setTestTypeAlphabetSorting: (state, action: PayloadAction<string>) => {
            state.testTypeAlphabetSorting = action.payload;
        },

        addStatus: (state, action: PayloadAction<StatusUnitInterface>) => {
            state.selectedStatuses.push(action.payload);
        },
        removeStatus: (state, action: PayloadAction<StatusUnitInterface>) => {
            state.selectedStatuses = state.selectedStatuses.filter(status => status.alias !== action.payload.alias);
        },
        clearStatuses: (state, action) => {
            state.selectedStatuses = [];
        },
        setStatuses: (state, action: PayloadAction<StatusUnitInterface[]>) => {
            state.selectedStatuses = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
    }
});

export const {
    setRowsInTable,

    selectTestType,
    removeSelectedTestType,
    clearSelectedTestTypes,
    setTestTypes,

    setDateSorting,

    setTestTypeAlphabetSorting,

    addStatus,
    removeStatus,
    clearStatuses,
    setStatuses,

    setPage,
} = AllOrdersFiltersAndSortersSlice.actions;

export const selectedTestTypesSelector = (state: RootState) => state.allOrdersFiltersAndSorters.selectedTestTypes;
export const rowsInTableSelector = (state: RootState) => state.allOrdersFiltersAndSorters.rowsInTable;
export const dateSortingSelector = (state: RootState) => state.allOrdersFiltersAndSorters.dateSorting;
export const testTypeAlphabetSortingSelector = (state: RootState) => state.allOrdersFiltersAndSorters.testTypeAlphabetSorting;
export const selectedStatusesSelector = (state: RootState) => state.allOrdersFiltersAndSorters.selectedStatuses;
export const pageSelector = (state: RootState) => state.allOrdersFiltersAndSorters.page;

export const AllOrdersFiltersAndSortersReducer = AllOrdersFiltersAndSortersSlice.reducer;
