import { Route, Routes } from "react-router-dom";
import { lazy, Suspense } from 'react';
import { CircularLoading } from "@/components/Loading/CircularLoading/CircularLoading";
import { useTranslation } from "react-i18next";

import UrlsConfig from "@/config/UrlsConfig";

const LoggedInChecker = lazy(() => import('@/pages/Auth/Login/Checkers/LoggedInChecker/LoggedInChecker'));
const NoPartnerRoleChecker = lazy(() => import('./Checkers/NoPartnerRoleChecker'));
const NotAuthenticatedChecker = lazy(() => import('@/routes/Checkers/NotAuthenticatedChecker/NotAuthenticatedChecker'));
const OpenedDNAOrderRolesChecker = lazy(() => import('@/pages/MyOrders/Nested/OpenedDNAOrder/Checkers/OpenedDNAOrderRolesChecker'));
const EditDNAOrderRolesChecker = lazy(() => import('@/pages/MyOrders/Nested/EditDNAOrder/Checkers/EditDNAOrderRolesChecker'));
const ArticleRolesChecker = lazy(() => import('@/pages/KnowledgeBase/Nested/Article/Checkers/ArticleRolesChecker'));
const FeedbackRolesChecker = lazy(() => import('./Checkers/FeedbackRolesChecker'));
const CreateNewOrderPageRolesChecker = lazy(() => import('@/pages/CreateNewOrder/Checkers/CreateNewOrderPageRolesChecker'));
const RegisterNewPartnerRolesChecker = lazy(() => import('@/pages/ControlPanel/Nested/RegisterNewPartner/Checkers/RegisterNewPartnerRolesChecker'));
const EditPartnerRoleChecker = lazy(() => import('./Checkers/EditPartnerRoleChecker'));
const AllOrdersRolesChecker = lazy(() => import('@/pages/ControlPanel/Nested/AllOrders/Checkers/AllOrdersRolesChecker'));
const OpenedOrderRolesChecker = lazy(() => import('@/pages/ControlPanel/Nested/AllOrders/Nested/OpenedOrder/Checkers/OpenedOrderRolesChecker'));
const KnowledgeBaseRolesChecker = lazy(() => import('@/pages/ControlPanel/Nested/KnowledgeBase/Checkers/KnowledgeBaseRolesChecker'));
const UsersListRoleChecker = lazy(() => import('./Checkers/UsersListRoleChecker/UsersListRoleChecker'));
const IsSupremeAdminRoleChecker = lazy(() => import('@/routes/Checkers/IsSupremeAdminChecker/IsSupremeAdminRoleChecker'));
const EditUserRoleChecker = lazy(() => import('./Checkers/EditUserRoleChecker'));
const ApplicationsForChangePersonalDataRolesChecker = lazy(() => import('@/pages/ControlPanel/Nested/ApplicationsForChangePersonalData/Checkers/ApplicationsForChangePersonalDataRolesChecker'));

const Layout = lazy(() => import('@/components/Layout/Layout'));

const Login = lazy(() => import('@/pages/Auth/Login/Login'));
const PrintPageForAllSamples = lazy(() => import('@/pages/MyOrders/PrintPages/AllSamples/PrintPageForAllSamples'));
const PrintPageForSingleSample = lazy(() => import('@/pages/MyOrders/PrintPages/SingleSample/PrintPageForSingleSample'));
const MyOrders = lazy(() => import('@/pages/MyOrders/MyOrders'));
const Profile = lazy(() => import('@/pages/Profile/Profile'));
const OpenedDNAOrder = lazy(() => import('@/pages/MyOrders/Nested/OpenedDNAOrder/OpenedDNAOrder'));
const EditDNAOrder = lazy(() => import('@/pages/MyOrders/Nested/EditDNAOrder/EditDNAOrder'));
const Index = lazy(() => import('@/pages/Index/Index'));
const KnowledgeBase = lazy(() => import('@/pages/KnowledgeBase/KnowledgeBase'));
const SearchResults = lazy(() => import('@/pages/KnowledgeBase/SearchResults'));
const Article = lazy(() => import('@/pages/KnowledgeBase/Nested/Article/Article'));
const Category = lazy(() => import('@/pages/KnowledgeBase/Category'));
const DNATests = lazy(() => import('@/pages/DNATests/DNATests'));
const FeedbackFromUsers = lazy(() => import('@/pages/Feedback/Feedback'));
const CreateNewAppeal = lazy(() => import('@/pages/Feedback/CreateNewAppeal'));
const OpenAppeal = lazy(() => import('@/pages/Feedback/OpenAppeal'));
const CreateNewOrder = lazy(() => import('@/pages/CreateNewOrder/CreateNewOrder'));
const ControlPanel = lazy(() => import('@/pages/ControlPanel/ControlPanel'));
const RegisterNewPartner = lazy(() => import('@/pages/ControlPanel/Nested/RegisterNewPartner/RegisterNewPartner'));
const EditPartner = lazy(() => import('@/pages/ControlPanel/Nested/EditPartner/EditPartner'));
const AllOrders = lazy(() => import('@/pages/ControlPanel/Nested/AllOrders/AllOrders'));
const OpenedOrder = lazy(() => import('@/pages/ControlPanel/Nested/AllOrders/Nested/OpenedOrder/OpenedOrder'));
const InCPKnowledgeBase = lazy(() => import('@/pages/ControlPanel/Nested/KnowledgeBase/KnowledgeBase'));
const InCPCreateArticle = lazy(() => import('@/pages/ControlPanel/Nested/KnowledgeBase/Nested/CreateArticle'));
const EditArticle = lazy(() => import('@/pages/ControlPanel/Nested/KnowledgeBase/Nested/EditArticle'));
const Search = lazy(() => import('@/pages/ControlPanel/Nested/KnowledgeBase/Nested/Search'));
const Users = lazy(() => import('@/pages/ControlPanel/Nested/Users/Users'));
const CreateUser = lazy(() => import('@/pages/ControlPanel/Nested/Users/CreateUser'));
const EditUser = lazy(() => import('@/pages/ControlPanel/Nested/Users/EditUser'));
const ApplicationsForChangePersonalData = lazy(() => import('@/pages/ControlPanel/Nested/ApplicationsForChangePersonalData/ApplicationsForChangePersonalData'));
const ViewApplication = lazy(() => import('@/pages/ControlPanel/Nested/ApplicationsForChangePersonalData/ViewApplication'));

const ForbiddenPage = lazy(() => import('@/pages/Error/ForbiddenPage'));
const Page404 = lazy(() => import('@/pages/Error/404/Page404'));

const AppRoutes = () => {

    const { t } = useTranslation();
    const urlsConfig = new UrlsConfig();

    return (
        <Suspense fallback={<CircularLoading text={`${t("Loading the module")}...`} />}>
            <Routes>
                <Route path={`${urlsConfig.printAllSamplesPage.to}/:orderNum`} element={<PrintPageForAllSamples />} />
                <Route path={urlsConfig.printSingleSample({ orderId: ":orderId", participantId: ":participantId" }).to} element={<PrintPageForSingleSample />} />

                <Route path={urlsConfig.index.to} element={<Layout />}>

                    <Route path={urlsConfig.login.to} element={<LoggedInChecker />}>
                        <Route path={urlsConfig.login.to} element={<Login />} />
                    </Route>

                    <Route path={urlsConfig.index.to} element={<NotAuthenticatedChecker />}>

                        <Route path={urlsConfig.myOrders.to} element={<MyOrders />} />
                        <Route path={urlsConfig.profile.to} element={<Profile />} />

                        <Route path={`${urlsConfig.openedOrder.to}/:orderNum`} element={<OpenedDNAOrderRolesChecker />}>
                            <Route path={`${urlsConfig.openedOrder.to}/:orderNum`} element={<OpenedDNAOrder />} />
                        </Route>
                        
                        <Route path={urlsConfig.editOrder({ orderId: ":orderId" }).to} element={<EditDNAOrderRolesChecker />}>
                            <Route path={urlsConfig.editOrder({ orderId: ":orderId" }).to} element={<EditDNAOrder />} />
                        </Route>
                        <Route path={urlsConfig.index.to} element={<Index />} />

                        <Route path={urlsConfig.knowledgeBase.to} element={<KnowledgeBase />} />
                        <Route path={`${urlsConfig.knowledgeBaseSearchResults.to}`} element={<SearchResults />} />
                        <Route path={`${urlsConfig.knowledgeBase.to}/article/:articleId`} element={<ArticleRolesChecker />}>
                            <Route path={`${urlsConfig.knowledgeBase.to}/article/:articleId`} element={<Article />} />
                        </Route>
                        <Route path={`${urlsConfig.knowledgeBase.to}/:categoryId/*`} element={<Category />} />

                        <Route path={urlsConfig.DNATests.to} element={<DNATests />} />

                        <Route path={urlsConfig.feedback.to} element={<FeedbackRolesChecker />}>
                            <Route path={urlsConfig.feedback.to} element={<FeedbackFromUsers />} />
                            <Route path={urlsConfig.createNewAppeal.to} element={<CreateNewAppeal />} />
                            <Route path={`${urlsConfig.openAppeal.to}/:id/*`} element={<OpenAppeal />} />
                        </Route>

                        <Route path={urlsConfig.createNewOrder.to} element={<CreateNewOrderPageRolesChecker />}>
                            <Route path={urlsConfig.createNewOrder.to} element={<CreateNewOrder />} />
                        </Route>

                        <Route path={urlsConfig.controlPanel.to} element={<NoPartnerRoleChecker />}>

                            <Route path={urlsConfig.controlPanel.to} element={<ControlPanel />} />

                            <Route path={urlsConfig.registerNewPartner.to} element={<RegisterNewPartnerRolesChecker />}>
                                <Route path={urlsConfig.registerNewPartner.to} element={<RegisterNewPartner />} />
                            </Route>
                            <Route path={`${urlsConfig.editPartner.to}/:partnerId`} element={<EditPartnerRoleChecker />}>
                                <Route path={`${urlsConfig.editPartner.to}/:partnerId`} element={<EditPartner />} />
                            </Route>

                            <Route path={urlsConfig.allOrders.to} element={<AllOrdersRolesChecker />}>
                                <Route path={urlsConfig.allOrders.to} element={<AllOrders />} />
                            </Route>

                            <Route path={`${urlsConfig.openedOrderInControlPanel.to}/:orderNum`} element={<OpenedOrderRolesChecker />}>
                                <Route path={`${urlsConfig.openedOrderInControlPanel.to}/:orderNum`} element={<OpenedOrder />} />
                            </Route>

                            <Route path={urlsConfig.inControlPanelKnowledgeBase.to} element={<KnowledgeBaseRolesChecker />}>
                                <Route path={urlsConfig.inControlPanelKnowledgeBase.to} element={<InCPKnowledgeBase />} />
                                <Route path={urlsConfig.inControlPanelKnowledgeBaseCreateArticle.to} element={<InCPCreateArticle />} />
                                <Route path={`${urlsConfig.inControlPanelKnowledgeBaseEditArticle.to}/:articleId`} element={<EditArticle />} />
                                <Route path={urlsConfig.inControlPanelKnowledgeBaseSearch.to} element={<Search />} />
                            </Route>

                            <Route path={urlsConfig.inControlPanelUsers.to} element={<UsersListRoleChecker />}>
                                <Route path={urlsConfig.inControlPanelUsers.to} element={<Users />} />
                            </Route>
                            <Route path={urlsConfig.inControlPanelCreateUser.to} element={<IsSupremeAdminRoleChecker />}>
                                <Route path={urlsConfig.inControlPanelCreateUser.to} element={<CreateUser />} />
                            </Route>
                            <Route path={`${urlsConfig.inControlPanelEditUser.to}/:userId`} element={<EditUserRoleChecker />}>
                                <Route path={`${urlsConfig.inControlPanelEditUser.to}/:userId`} element={<EditUser />} />
                            </Route>

                            <Route path={urlsConfig.inControlPanelApplicationsForChangePersonalData.to} element={<ApplicationsForChangePersonalDataRolesChecker />}>
                                <Route path={urlsConfig.inControlPanelApplicationsForChangePersonalData.to} element={<ApplicationsForChangePersonalData />} />
                            </Route>
                            <Route path={`${urlsConfig.inControlPanelViewApplicationForChangePersonalData.to}/:applicationId`} element={<ApplicationsForChangePersonalDataRolesChecker />}>
                                <Route path={`${urlsConfig.inControlPanelViewApplicationForChangePersonalData.to}/:applicationId`} element={<ViewApplication />} />
                            </Route>
                        </Route>

                        <Route path={urlsConfig.pageForbidden.to} element={<ForbiddenPage />} />
                    </Route>

                    <Route path="*" element={<Page404 />} />
                </Route>
            </Routes>

        </Suspense>
    )
};

export { AppRoutes }