import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {RootState} from "@/store/Store";
import { FileInterface, MeaningType } from '../FilesUploader/Interfaces/FileInterface';

function getInitialState() {
    return {
        files: [
            {
                id: uuidv4(),
                name: "",
                meaning: "notChosen",
            },
        ] as FileInterface[],
    }
}

export const OpenedOrderLabFilesSlice = createSlice({
    name: "OpenedOrderLabFilesSlice",
    initialState: getInitialState(),
    reducers: {
        setFileInfo: (state, action: PayloadAction<FileInterface>) => {
            state.files = state.files.map(file => {
                if (file.id !== action.payload.id) {
                    return file;
                }
                return {
                    id: file.id,
                    name: action.payload.name,
                    meaning: action.payload.meaning,
                }
            });
        },
        addDocument: (state, action: PayloadAction<void>) => {
            state.files.push({
                id: uuidv4(),
                name: "",
                meaning: "notChosen",
            })
        },
        deleteDocument: (state, action: PayloadAction<FileInterface["id"]>) => {
            state.files = state.files.filter(file => file.id !== action.payload);
        },
        setFileMeaning: (state, action: PayloadAction<{id: string, value: MeaningType,}>) => {
            state.files = state.files.map(file => {
                if (file.id !== action.payload.id) {
                    return file;
                }
                return {
                    id: file.id,
                    name: file.name,
                    meaning: action.payload.value,
                }
            });
        },
        reset: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.files = initialState.files;
        }
    },
});

export const {
    setFileInfo,
    addDocument,
    deleteDocument,
    setFileMeaning,
    reset,
} = OpenedOrderLabFilesSlice.actions;

export const filesSelector = (state: RootState) => state.openedDNAOrderLabFiles.files;

export const OpenedOrderLabFilesReducer = OpenedOrderLabFilesSlice.reducer;