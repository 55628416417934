import { RootState } from "@/store/Store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import {OrderDNATestFormConfig} from "@/features/OrderDNATestForm/Config/OrderDNATestFormConfig";
import { ParticipantInterface } from "@/api/Orders/OpenedOrder/Interfaces/ParticipantInterface";
import dayjs from "dayjs";

export const notSelectedId = -1;

function getInitialState() {
    return {
        step: 0,

        step1: {
            orderId: {
                value: "",
                valid: true,
                error: "",
            },
            testType: {
                value: "",
                valid: false,
                error: "",
            },
            testTypeOther: {
                value: "",
                valid: false,
                error: "",
            },
            legalStatus: {
                value: "",
                valid: false,
                error: "",
            },
            attribute: {
                value: "",
                valid: false,
                error: "",
            },
            researchPeriod: {
                value: "",
                valid: false,
                error: "",
            },
        },
        step2: {
            participantId: "",

            fioOrNickname: {
                value: "",
                valid: false,
                error: "",
            },
            birthday: {
                value: "",
                valid: true,
                error: "",
            },
            degreeOfKinship: {
                value: notSelectedId,
                valid: false,
                error: "",
            },
            degreeOfKinshipPreviousOrderNumber: {
                value: "",
                valid: false,
                error: "",
            },
            sampleType: {
                value: notSelectedId,
                valid: false,
                error: "",
            },
            sampleTypeOther: {
                value: "",
                valid: false,
                error: "",
            },
            whoCollectedSamples: {
                value: notSelectedId,
                valid: false,
                error: "",
            },

            isUserNewOrEdit: "new" as userNewOrEdit,

            testParticipants: [] as TestParticipantInterface[],
        },
        step3: {
            isThirdStepValid: true,

            //additional info
            pregnancyPeriod: {
                value: "",
                valid: false,
                error: "",
            },
            pregnancyPeculiarities: {
                value: "0",
                valid: false,
                error: "",
            },
            describeKinshipTies: {
                value: "",
                valid: false,
                error: "",
            },
            anotherImportantInfo: {
                value: "",
                valid: false,
                error: "",
            },
            trackNumber: {
                value: "",
                valid: false,
                error: "",
            },

            //additional services
            copyOfReport: {
                value: false,
                valid: true,
                error: "",
            },
            urgentDispatchOfOpinionsByCourier: {
                value: false,
                valid: true,
                error: "",
            },
            translationOfConclusionIntoForeignLanguage: {
                value: false,
                valid: true,
                error: "",
            },
            returnOfSamples: {
                value: false,
                valid: true,
                error: "",
            },
            writtenConsultationWithSpecialistOnTest: {
                value: false,
                valid: true,
                error: "",
            },

            files: [
                {
                    id: uuidv4(),
                    file: null,
                    meaning: "",
                },
                {
                    id: uuidv4(),
                    file: null,
                    meaning: "",
                },
            ] as UploadingFileInterface[],
        },
    };
}

export const EditDNATestFormSlice = createSlice({
    name: "EditDNATestFormSlice",
    initialState: getInitialState(),
    reducers: {
        setStep: (state, action: PayloadAction<number>) => {
            state.step = action.payload;
        },

        setFormValue: (state, action) => {
            const {step, name, value} = action.payload;
            //@ts-ignore
            state[step][name].value = value;
        },
        setFormError: (state, action) => {
            const {step, name, error} = action.payload;
            //@ts-ignore
            state[step][name].error = error;
        },
        setFormValidation: (state, action) => {
            const {step, name, valid} = action.payload;
            //@ts-ignore
            state[step][name].valid = valid;
        },

        addTestParticipant: (state, action) => {
            const id = uuidv4();
            const fioOrNickname = state.step2.fioOrNickname.value;
            const birthday = state.step2.birthday.value;
            const degreeOfKinship = state.step2.degreeOfKinship.value;
            const degreeOfKinshipPreviousOrderNumber = state.step2.degreeOfKinshipPreviousOrderNumber.value;
            const sampleType = state.step2.sampleType.value;
            const sampleTypeOther = state.step2.sampleTypeOther.value;
            const whoCollectedSamples = state.step2.whoCollectedSamples.value;

            state.step2.testParticipants.push({
                id, fioOrNickname, birthday, degreeOfKinship,
                degreeOfKinshipPreviousOrderNumber, sampleType,
                sampleTypeOther, whoCollectedSamples
            });
        },
        clearParticipantForm: (state, action: PayloadAction<void>) => {
            const newInitialState = getInitialState();

            state.step2.fioOrNickname = newInitialState.step2.fioOrNickname;
            state.step2.birthday = newInitialState.step2.birthday;
            state.step2.degreeOfKinship = newInitialState.step2.degreeOfKinship;
            state.step2.degreeOfKinshipPreviousOrderNumber = newInitialState.step2.degreeOfKinshipPreviousOrderNumber;
            state.step2.sampleType = newInitialState.step2.sampleType;
            state.step2.sampleTypeOther = newInitialState.step2.sampleTypeOther;
            state.step2.whoCollectedSamples = newInitialState.step2.whoCollectedSamples;
        },
        saveEditedTestParticipant: (state, action) => {
            state.step2.testParticipants = state.step2.testParticipants.map(participant => {
                if (participant.id !== state.step2.participantId) {
                    return participant;
                }
                const editedParticipant: TestParticipantInterface = {
                    id: participant.id,
                    fioOrNickname: state.step2.fioOrNickname.value,
                    birthday: state.step2.birthday.value,
                    degreeOfKinship: state.step2.degreeOfKinship.value,
                    degreeOfKinshipPreviousOrderNumber: state.step2.degreeOfKinshipPreviousOrderNumber.value,
                    sampleType: state.step2.sampleType.value,
                    sampleTypeOther: state.step2.sampleTypeOther.value,
                    whoCollectedSamples: state.step2.whoCollectedSamples.value,
                };
                return editedParticipant;
            });
        },
        setParticipantFormStatus: (state, action) => {
            state.step2.isUserNewOrEdit = action.payload.status;
        },
        deleteTestParticipant: (state, action) => {
            state.step2.testParticipants = state.step2.testParticipants.filter(participant => participant.id !== action.payload.id);
        },
        editTestParticipant: (state, action) => {
            const id = action.payload.id;
            const participant = state.step2.testParticipants.find(participant => participant.id === id) as TestParticipantInterface;
            state.step2.participantId = participant.id;
            state.step2.fioOrNickname.value = participant.fioOrNickname;
            state.step2.birthday.value = participant.birthday;
            state.step2.degreeOfKinship.value = participant.degreeOfKinship;
            state.step2.degreeOfKinshipPreviousOrderNumber.value = participant.degreeOfKinshipPreviousOrderNumber;
            state.step2.sampleType.value = participant.sampleType;
            state.step2.sampleTypeOther.value = participant.sampleTypeOther;
            state.step2.whoCollectedSamples.value = participant.whoCollectedSamples;
        },
        makeParticipantFormValid: (state, action) => {
            state.step2.fioOrNickname.valid = true;
            state.step2.birthday.valid = true;
            state.step2.degreeOfKinship.valid = true;
            state.step2.degreeOfKinshipPreviousOrderNumber.valid = state.step2.degreeOfKinshipPreviousOrderNumber.value !== "";
            state.step2.sampleType.valid = true;
            state.step2.sampleTypeOther.valid = state.step2.sampleTypeOther.value !== "";
            state.step2.whoCollectedSamples.valid = true;
        },
        setTestParticipants: (state, action: PayloadAction<ParticipantInterface[]>) => {
            state.step2.testParticipants = action.payload.map(participant => {
                return {
                    id: `${participant.id}`,
                    fioOrNickname: participant.fioOrNickname,
                    birthday: dayjs(participant.birthday).format("DD.MM.YYYY"),
                    degreeOfKinship: participant.family_link.id,
                    degreeOfKinshipPreviousOrderNumber: participant.old_order ?? "",
                    sampleType: participant.sample.id,
                    sampleTypeOther: participant.sampleTypeOther,
                    whoCollectedSamples: participant.sampling.id,
                }
            });
        },

        addDocumentRow: (state, action) => {
            if (state.step3.files.length === OrderDNATestFormConfig.maxDocumentsCount) {
                return;
            }
            const doc: UploadingFileInterface = {
                id: uuidv4(),
                file: null,
                meaning: "",
            };
            state.step3.files.push(doc);
        },
        deleteDocumentRow: (state, action) => {
            const id = action.payload.id;
            state.step3.files = state.step3.files.filter(file => file.id !== id);
        },
        setFileInfo: (state, action) => {
            const id = action.payload.id;
            const {name, size} = action.payload.file;
            state.step3.files = state.step3.files.map(fileInSlice => {
                if (fileInSlice.id !== id) {
                    return fileInSlice;
                }
                fileInSlice.file = {name, size};
                return fileInSlice;
            })
        },
        setFileMeaning: (state, action) => {
            const {id, value} = action.payload;
            state.step3.files = state.step3.files.map(fileInSlice => {
                if (fileInSlice.id !== id) {
                    return fileInSlice;
                }
                fileInSlice.meaning = value;
                return fileInSlice;
            })
        },
        resetFiles: (state, action: PayloadAction<void>) => {
            const initialState = getInitialState();
            state.step3.files = initialState.step3.files;
        }
    }
});

export const {
    setStep,

    setFormValue,
    setFormError,
    setFormValidation,

    addTestParticipant,
    clearParticipantForm,
    saveEditedTestParticipant,
    setParticipantFormStatus,
    deleteTestParticipant,
    editTestParticipant,
    makeParticipantFormValid,
    setTestParticipants,

    addDocumentRow,
    deleteDocumentRow,
    setFileInfo,
    setFileMeaning,
    resetFiles,
} = EditDNATestFormSlice.actions;

export const getSelectorForStep = (state: RootState) => state.editDNATestForm.step;

//@ts-ignore
export const getSelectorForValue = (step, name) => (state: RootState) => state.editDNATestForm[step][name].value;
//@ts-ignore
export const getSelectorForError = (step, name) => (state: RootState) => state.editDNATestForm[step][name].error;
//@ts-ignore
export const getSelectorForValidation = (step, name) => (state: RootState) => state.editDNATestForm[step][name].valid;
export const getSelectorForParticipantId = (state: RootState) => state.editDNATestForm.step2.participantId;

export const isFirstStepValidSelector = (state: RootState) => {
    const testTypeValid = state.editDNATestForm.step1.testType.valid;
    const testTypeOtherValid = state.editDNATestForm.step1.testTypeOther.valid;

    const testTypeValidation = (testTypeValid && state.editDNATestForm.step1.testType.value !== "testTypeOther") || (testTypeValid && testTypeOtherValid);
    const legalStatusValid = state.editDNATestForm.step1.legalStatus.valid;
    const attributeValid = state.editDNATestForm.step1.attribute.valid;
    const researchPeriodValid = state.editDNATestForm.step1.researchPeriod.valid;

    return testTypeValidation && legalStatusValid && attributeValid && researchPeriodValid;
};

export const isSecondStepCompletedSelector = (state: RootState) => {
    return state.editDNATestForm.step2.testParticipants.length > 0;
};

export const isThirdStepValidSelector = (state: RootState) => {
    const totalSizeMb = getChosenFilesTotalSize(state) / 1000000;
    const filesTotalSizeValid = totalSizeMb <= OrderDNATestFormConfig.maxFilesSizeMb;

    const chosenFilesCount = getChosenFilesCount(state);
    const filesCountValid = chosenFilesCount <= OrderDNATestFormConfig.maxDocumentsCount;

    const filesMeaningIsValid = isMeaningOfFilesValid(state);

    return filesTotalSizeValid && filesCountValid && filesMeaningIsValid;
}

export const participantFormStateSelector = (state: RootState) => {
    return state.editDNATestForm.step2.isUserNewOrEdit;
};

export const isParticipantFormValidSelector = (state: RootState) => {
    const fioOrNicknameValid = state.editDNATestForm.step2.fioOrNickname.valid;
    const birthdayValid = state.editDNATestForm.step2.birthday.valid;

    const degreeOfKinshipValid = state.editDNATestForm.step2.degreeOfKinship.valid;
    const degreeOfKinshipPreviousOrderNumberValid = state.editDNATestForm.step2.degreeOfKinshipPreviousOrderNumber.valid;
    const degreeOfKinshipValidation = (degreeOfKinshipValid && state.editDNATestForm.step2.degreeOfKinship.value !== 39) || (degreeOfKinshipValid && degreeOfKinshipPreviousOrderNumberValid);

    const sampleTypeValid = state.editDNATestForm.step2.sampleType.valid;
    const sampleTypeOtherValid = state.editDNATestForm.step2.sampleTypeOther.valid;
    const sampleTypeValidation = (sampleTypeValid && state.editDNATestForm.step2.sampleType.value !== 60) || (sampleTypeValid && sampleTypeOtherValid);

    const whoCollectedSamplesValid = state.editDNATestForm.step2.whoCollectedSamples.valid;

    return fioOrNicknameValid && birthdayValid && degreeOfKinshipValidation && sampleTypeValidation && whoCollectedSamplesValid;
}

export const getSelectorForParticipants = (state: RootState) => state.editDNATestForm.step2.testParticipants;

export const chosenFilesCountSelector = (state: RootState) => {
    return getChosenFilesCount(state);
};

export const chosenFilesSizeSelector = (state: RootState) => {
    return getChosenFilesTotalSize(state);
};

export const filesSelector = (state: RootState) => {
    return state.editDNATestForm.step3.files;
};

export const fileByIdSelector = (id: string | number) => (state: RootState) => {
    const found = state.editDNATestForm.step3.files.find((file: UploadingFileInterface) => {
        return file.id === id;
    });
    if (found === undefined) {
        throw new Error(`File with id: ${id} not found.`);
    }
    return found;
}

function getChosenFilesTotalSize(state: RootState) {
    let size = 0;
    state.editDNATestForm.step3.files.forEach((file: UploadingFileInterface) => {
        if ("file" in file && file.file !== null) {
            size += file.file.size;
        }
    });
    return size;
}

function getChosenFilesCount(state: RootState) {
    let count = 0;
    state.editDNATestForm.step3.files.forEach((file: UploadingFileInterface) => {
        if ("file" in file && file.file !== null) {
            count++;
        }
    });
    return count;
}

function isMeaningOfFilesValid(state: RootState) {
    let valid = true;
    state.editDNATestForm.step3.files.forEach((file: UploadingFileInterface) => {
        if ("file" in file && file.file !== null && file.meaning === "") {
            valid = false;
        }
    });
    return valid;
}

export const EditDNATestFormReducer = EditDNATestFormSlice.reducer;

export interface UploadingFileInterface {
    id: string,
    file: {name: string, size: number} | null,
    meaning: string,
}

export interface TestParticipantInterface {
    id: string,
    fioOrNickname: string,
    birthday: string,
    degreeOfKinship: number,
    degreeOfKinshipPreviousOrderNumber: string,
    sampleType: number,
    sampleTypeOther: string,
    whoCollectedSamples: number,
}

export type userNewOrEdit = "new" | "edit";