import {createSlice} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import {RootState} from "@/store/Store";
import {saveNewCategoryThunk} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Thunks/saveNewCategoryThunk";

export const noParentCategoryId = "no";

function getInitialState() {
    return {
        newCategoryTitle: "",
        newCategoryParentId: noParentCategoryId,
        dialogIsOpened: false,
        saveNewCategoryRequestStatus: LoadingStatus.idle as LoadingStatus,
    }
}

export const KnowledgeBaseAddCategorySlice = createSlice({
    name: "KnowledgeBaseAddCategorySlice",
    initialState: getInitialState(),
    reducers: {
        changeCategoryTitle: (state, action) => {
            state.newCategoryTitle = action.payload;
        },
        setNewCategoryParentId: (state, action) => {
            state.newCategoryParentId = action.payload;
        },
        setDialogIsOpened: (state, action) => {
            state.dialogIsOpened = action.payload;
            if ( ! action.payload) {
                const initialState = getInitialState();
                state.newCategoryTitle = initialState.newCategoryTitle;
                state.newCategoryParentId = initialState.newCategoryParentId;
            }
        },
    },

    extraReducers(builder) {
        builder
        .addCase(saveNewCategoryThunk.pending, (state, action) => {
            state.saveNewCategoryRequestStatus = LoadingStatus.loading;
        })
        .addCase(saveNewCategoryThunk.fulfilled, (state, action) => {
            state.saveNewCategoryRequestStatus = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success") {
                const initialState = getInitialState();
                state.dialogIsOpened = initialState.dialogIsOpened;
                state.newCategoryTitle = initialState.newCategoryTitle;
                state.newCategoryParentId = initialState.newCategoryParentId;
            }
        })
        .addCase(saveNewCategoryThunk.rejected, (state, action) => {
            state.saveNewCategoryRequestStatus = LoadingStatus.failed;
        })
    },
});

export const {
    changeCategoryTitle,
    setNewCategoryParentId,
    setDialogIsOpened,
} = KnowledgeBaseAddCategorySlice.actions;

export const newCategoryTitleSelector = (state: RootState) => state.inCPAddCategoryInKnowledgeBase.newCategoryTitle;
export const newCategoryParentIdSelector = (state: RootState) => state.inCPAddCategoryInKnowledgeBase.newCategoryParentId;
export const newCategoryDialogIsOpenedSelector = (state: RootState) => state.inCPAddCategoryInKnowledgeBase.dialogIsOpened;
export const saveNewCategoryRequestStatusSelector = (state: RootState) => state.inCPAddCategoryInKnowledgeBase.saveNewCategoryRequestStatus;

export const KnowledgeBaseAddCategoryReducer = KnowledgeBaseAddCategorySlice.reducer;