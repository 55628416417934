import {createAsyncThunk} from "@reduxjs/toolkit";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios, {AxiosError} from "axios";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import {showPopup} from "@/features/PopupMessages/PopupMessages";
import i18n from "@/i18n/i18n";
import configuredStore from "@/store/Store";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";

export const getCategoriesInTrashCountThunk = createAsyncThunk(
    "categoriesInTrash/getCategories",
    async () => {
        const restApiConfig = new RESTAPIConfig();
        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getKnowledgeBaseGetCategoriesInTrash();
        const userLang = configuredStore.getState().lang.userLang;
        const token = getAccessToken(configuredStore.getState());
        try {
            const response = await axios.get<JsonResponseInterface<unknown>>(url, {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    [userLangHeaderName]: userLang,
                    "Authorization": `Bearer ${token}`,
                }
            });

            return response.data;

        } catch (e: unknown) {
            const error = e as AxiosError;
            //@ts-ignore
            const message = i18n.t("Error with code and text", {code: error.response.status, error: error.response.data.errors.toString()});
            showPopup({
                type: "error",
                message,
                autoClose: 5000,
            });
        }
    },
);