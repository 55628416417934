import { createSlice } from "@reduxjs/toolkit";
import {LoginFormApiSlice} from "@/api/Auth/Login/LoginFormApiSlice";
import {AccessTokenStorageService} from "@/features/Auth/CurrentUser/Services/AccessTokenStorageService";
import {UserDataInterface} from "@/api/Auth/Login/Interfaces/UserDataInterface";
import {RootState} from "@/store/Store";
import {logoutThunk} from "@/features/Auth/CurrentUser/Slice/LogoutSlice";
import { GetCurrentUserApiSlice } from "@/api/Users/GetCurrentUser/GetCurrentUserApiSlice";

export const notAuthenticatedUserId = -1;

function getUserInitialState() {
    const user: UserDataInterface["user"] = {
        email: "",
        id: notAuthenticatedUserId,
        image: null,
        name: "",
        position: null,
        roles: [],
        schedule: null,
        tel: null,
        is_first_entry: 0,
    };
    return user;
}

function getInitialSlice() {
    const tokenStorageService = new AccessTokenStorageService();
    const token = tokenStorageService.getTokenFromStorage();

    const sliceState: UserDataInterface = {
        token,
        user: getUserInitialState(),
    };
    return sliceState;
}

export const CurrentUserSlice = createSlice({
    name: "CurrentUserSlice",
    initialState: getInitialSlice(),
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(logoutThunk.fulfilled, (state, action) => {
                if (action.payload.result === "success") {
                    state.user = getUserInitialState();
                    state.token = "";
                }
            })
            .addMatcher(LoginFormApiSlice.endpoints.loginUser.matchFulfilled, (state, action) => {
                if (action.payload.result === "success") {
                    state.token = action.payload.data.token;
                    state.user = action.payload.data.user;
                }
            })
            .addMatcher(GetCurrentUserApiSlice.endpoints.getCurrentUser.matchFulfilled, (state, action) => {
                if (action.payload.result === "success") {
                    state.user = action.payload.data;
                }
            })
    }
});

export const {
    setToken,
} = CurrentUserSlice.actions;

export const tokenSelector = (state: RootState) => state.currentUser.token;
export const userSelector = (state: RootState) => state.currentUser.user;

export const CurrentUserReducer = CurrentUserSlice.reducer;