import {createSlice} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import { v4 as uuidv4 } from 'uuid';
import {UploadingFileInterface} from "@/features/InControlPanel/DNAOrderContent/Interfaces/UploadingFileInterface";
import {RootState} from "@/store/Store";
import {
    LabDocumentsUploadingConfig
} from "@/features/InControlPanel/DNAOrderContent/Config/LabDocumentsUploadingConfig";
import {uploadFilesThunk} from "@/features/InControlPanel/DNAOrderContent/Thunks/uploadFilesThunk";

function getInitialState() {
    return {
        filesUploadingStatus: "idle" as LoadingStatus,
        files: [
            {
                id: uuidv4(),
                file: null,
                meaning: "",
            },
        ] as UploadingFileInterface[],
    }
}

export const LabDocumentsUploadingSlice = createSlice({
    name: "LabDocumentsUploadingSlice",
    initialState: getInitialState(),
    reducers: {
        setFileMeaning: (state, action) => {
            const {id, value} = action.payload;
            state.files = state.files.map(fileInSlice => {
                if (fileInSlice.id !== id) {
                    return fileInSlice;
                }
                fileInSlice.meaning = value;
                return fileInSlice;
            })
        },
        setFileInfo: (state, action) => {
            const id = action.payload.id;
            const {name, size} = action.payload.file;
            state.files = state.files.map(fileInSlice => {
                if (fileInSlice.id !== id) {
                    return fileInSlice;
                }
                fileInSlice.file = {name, size};
                return fileInSlice;
            })
        },
        deleteDocumentRow: (state, action) => {
            const id = action.payload.id;
            state.files = state.files.filter(file => file.id !== id);
        },
        addDocumentRow: (state, action) => {
            if (state.files.length === LabDocumentsUploadingConfig.maxDocumentsCount) {
                return;
            }
            const doc: UploadingFileInterface = {
                id: uuidv4(),
                file: null,
                meaning: "",
            };
            state.files.push(doc);
        },
    },

    extraReducers(builder) {
        builder
        .addCase(uploadFilesThunk.pending, (state, action) => {
            state.filesUploadingStatus = LoadingStatus.loading;
        })
        .addCase(uploadFilesThunk.fulfilled, (state, action) => {
            state.filesUploadingStatus = LoadingStatus.succeeded;

            if (action.payload?.result === "success") {
                const initial = getInitialState();
                state.files = initial.files;
                state.filesUploadingStatus = initial.filesUploadingStatus;
            }

        })
        .addCase(uploadFilesThunk.rejected, (state, action) => {
            state.filesUploadingStatus = LoadingStatus.failed;
        })
    }
});

export const {
    setFileMeaning,
    setFileInfo,
    deleteDocumentRow,
    addDocumentRow,
} = LabDocumentsUploadingSlice.actions;

export const uploadingFilesSelector = (state: RootState) => state.labDocumentsUploading.files;
export const filesUploadingStatusSelector = (state: RootState) => state.labDocumentsUploading.filesUploadingStatus;
export const fileByIdSelector = (id: string) => (state: RootState) => {
    const found = state.labDocumentsUploading.files.find((file: UploadingFileInterface) => {
        return file.id === id;
    });
    if (found === undefined) {
        throw new Error(`File with id: ${id} not found.`);
    }
    return found;
};
export const filesAreReadyToUploadSelector = (state: RootState) => {
    const notNullFile = state.labDocumentsUploading.files.find(file => file.file !== null);
    if (notNullFile === undefined) {
        return false;
    }
    const allMeaningsAreSet = state.labDocumentsUploading.files.every(file => {
        return file.file !== null && file.meaning !== "";
    })
    return allMeaningsAreSet;
}

export const LabDocumentsUploadingReducer = LabDocumentsUploadingSlice.reducer;