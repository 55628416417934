import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import { ContactValidationErrorsInterface } from "../Interfaces/ContactValidationErrorsInterface";

export const notSelectedRoleId = "choose";

const initialState = {
    contacts: [] as ContactInterface[],
    formStage: "new",
    form: {
        id: "",
        role: notSelectedRoleId,
        fio: "",
        phone: "",
        email: "",
        position: "",
        note: "",
    },
    formErrors: {
        role: "",
        fio: "",
        phone: "",
        email: "",
        position: "",
        note: "",
    },
    validation: {
        role: false,
        fio: false,
        phone: false,
        email: false,
        position: true,
        note: true,
    },

    validationErrorsFromServer: [] as ContactValidationErrorsInterface[],
}

export const ContactsSlice = createSlice({
    name: "ContactsSlice",
    initialState,
    reducers: {
        setFormUnit: (state, action) => {
            //@ts-ignore
            state.form[action.payload.name] = action.payload.value;
        },
        setFormError: (state, action) => {
            //@ts-ignore
            state.formErrors[action.payload.name] = action.payload.value;
        },
        setFormValidation: (state, action) => {
            //@ts-ignore
            state.validation[action.payload.name] = action.payload.value;
        },
        addContact: (state, action) => {
            state.contacts.push({...state.form, id: uuidv4()});
        },
        editContact: (state, action) => {
            const currentContactId = state.form.id;
            state.contacts = state.contacts.map(contact => {
                if (currentContactId === contact.id) {
                    return state.form;
                }
                return contact;
            })
        },
        deleteContact: (state, action) => {
            state.contacts = state.contacts.filter(contact => {
                return contact.id !== action.payload;
            });
        },
        setFormToDefault: (state, action) => {
            state.form = {
                id: "",
                role: notSelectedRoleId,
                fio: "",
                phone: "",
                email: "",
                position: "",
                note: "",
            };
        },
        setValidationToDefault: (state, action) => {
            state.validation = {
                role: false,
                fio: false,
                phone: false,
                email: false,
                position: true,
                note: true,
            };
        },
        setValidationToValid: (state, action) => {
            state.validation = {
                role: true,
                fio: true,
                phone: true,
                email: true,
                position: true,
                note: true,
            };
        },
        setFormStage: (state, action) => {
            state.formStage = action.payload;
        },
        setContactForm: (state, action) => {
            const contact = action.payload as ContactInterface;
            //@ts-ignore
            state.form = {...contact};
        },
        setContactsListToEmpty: (state, action) => {
            state.contacts = [];
        },

        setValidationErrorsFromServer: (state, action: PayloadAction<ContactValidationErrorsInterface[]>) => {
            state.validationErrorsFromServer = action.payload;
        },
    }
});

export const {
    setFormUnit,
    setFormError,
    setFormValidation,
    addContact,
    editContact,
    deleteContact,
    setFormToDefault,
    setValidationToDefault,
    setFormStage,
    setContactForm,
    setValidationToValid,
    setContactsListToEmpty,
    setValidationErrorsFromServer,
} = ContactsSlice.actions;

//@ts-ignore
export const roleSelector = state => state.contacts.form.role;
//@ts-ignore
export const formSelector = state => state.contacts.form;
//@ts-ignore
export const formErrorsSelector = state => state.contacts.formErrors;
//@ts-ignore
export const contactsSelector = state => state.contacts.contacts;
//@ts-ignore
export const formStageSelector = state => state.contacts.formStage;
//@ts-ignore
export const validationErrorsFromServerSelector = state => state.contacts.validationErrorsFromServer;

//@ts-ignore
export const contactsAreCorrectSelector = state => {
    return state.contacts.validation.role &&
           state.contacts.validation.fio &&
           state.contacts.validation.phone &&
           state.contacts.validation.email &&
           state.contacts.validation.position &&
           state.contacts.validation.note;
};

export const ContactsReducer = ContactsSlice.reducer;

export interface ContactInterface {
    id: string,
    role: string,
    fio?: string,
    phone?: string,
    email?: string,
    position?: string,
    note?: string,
}

export const FormStage = {
    new: "new",
    edit: "edit",
}