import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoadingStatus} from "@/store/LoadingStatus";
import {RootState} from "@/store/Store";
import {
    ArticleShortInfoInterface
} from "@/features/InControlPanel/KnowledgeBase/ArticlesOfCategory/Interfaces/ArticleShortInfoInterface";
import {
    getArticlesOfCategoryThunk
} from "@/features/InControlPanel/KnowledgeBase/ArticlesOfCategory/Thunks/getArticlesOfCategoryThunk";
import {
    changeArticleInTrashThunk
} from "@/features/InControlPanel/KnowledgeBase/ArticlesOfCategory/Thunks/changeArticleInTrashThunk";

function getInitialSlice() {
    return {
        articlesAreLoading: LoadingStatus.idle as LoadingStatus,
        articles: [] as ArticleShortInfoInterface[],

        articlesInTrashChangingIds: [] as string[],
    }
}

export const ArticlesOfCategorySlice = createSlice({
    name: "ArticlesOfCategorySlice",
    initialState: getInitialSlice(),
    reducers: {
        setArticles: (state, action: PayloadAction<ArticleShortInfoInterface[]>) => {
            state.articles = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(getArticlesOfCategoryThunk.pending, (state, action) => {
            state.articlesAreLoading = LoadingStatus.loading;
        })
        .addCase(getArticlesOfCategoryThunk.fulfilled, (state, action) => {
            state.articlesAreLoading = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success") {
                state.articles = action.payload.data as ArticleShortInfoInterface[];
            }
        })
        .addCase(getArticlesOfCategoryThunk.rejected, (state, action) => {
            state.articlesAreLoading = LoadingStatus.failed;
        })

        .addCase(changeArticleInTrashThunk.pending, (state, action) => {
            const pendingInTrashArticleId = action.meta.arg.articleId;
            state.articlesInTrashChangingIds.push(pendingInTrashArticleId);
        })
        .addCase(changeArticleInTrashThunk.fulfilled, (state, action) => {
            const pendingInTrashArticleId = action.meta.arg.articleId;
            state.articlesInTrashChangingIds = state.articlesInTrashChangingIds.filter(id => id !== pendingInTrashArticleId);

            if (action.payload === undefined) {
                return;
            }

            const inTrash = action.meta.arg.inTrash;
            if (action.payload.result === "success") {
                state.articles = state.articles.map(article => {
                    if (article.id.toString() !== pendingInTrashArticleId) {
                        return article;
                    }

                    article.inTrash = Number(inTrash);
                    return article;
                });
            }
        })
        .addCase(changeArticleInTrashThunk.rejected, (state, action) => {
            //state.articlesAreLoading = LoadingStatus.failed;
            const pendingInTrashArticleId = action.meta.arg.articleId;
            state.articlesInTrashChangingIds = state.articlesInTrashChangingIds.filter(id => id !== pendingInTrashArticleId);
        })
    }
});

export const {
    setArticles,
} = ArticlesOfCategorySlice.actions;

export const articlesAreLoadingSelector = (state: RootState) => state.inCPArticlesOfCategoryOfKnowledgeBase.articlesAreLoading;
export const articlesSelector = (state: RootState) => state.inCPArticlesOfCategoryOfKnowledgeBase.articles;
export const articlesInTrashChangingIdsSelector = (state: RootState) => state.inCPArticlesOfCategoryOfKnowledgeBase.articlesInTrashChangingIds;

export const ArticlesOfCategoryReducer = ArticlesOfCategorySlice.reducer;