import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "@/store/Store";
import {LoadingStatus} from "@/store/LoadingStatus";
import {saveArticleThunk} from "@/features/InControlPanel/KnowledgeBase/EditArticleForm/Thunks/saveArticleThunk";
import {fetchArticleThunk} from "@/features/InControlPanel/KnowledgeBase/EditArticleForm/Thunks/fetchArticleThunk";
import {getThreeThunk} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Thunks/getThreeThunk";
import {
    CategoriesListItemInterface
} from "@/features/InControlPanel/KnowledgeBase/CategoriesTree/Interfaces/CategoriesListItemInterface";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import {
    ArticleShortInfoInterface
} from "@/features/InControlPanel/KnowledgeBase/ArticlesOfCategory/Interfaces/ArticleShortInfoInterface";
import { EditArticleFormInterface } from "../Thunks/Interfaces/EditArticleFormInterface";

export const notSelectedId = -1;

function getInitialState() {
    return {
        formLoadingStatus: LoadingStatus.idle as LoadingStatus,
        categoriesLoadingStatus: LoadingStatus.idle as LoadingStatus,
        categoriesList: [] as CategoriesListItemInterface[],

        form: {
            id: "",
            category: notSelectedId,
            title: "",
            previewFileUrl: "",
            newPreviewFileName: "",
            shortDesc: "",
            fullText: "",
        } as EditArticleFormInterface,

        validationErrors: {
        }
    } ;
}

export const EditArticleFormSlice = createSlice({
    name: "EditArticleFormSlice",
    initialState: getInitialState(),
    reducers: {
        setCategory: (state, action: PayloadAction<number>) => {
            state.form.category = action.payload;
        },
        setPreviewFileUrl: (state, action: PayloadAction<string>) => {
            state.form.previewFileUrl = action.payload;
        },
        setNewPreviewFileName: (state, action: PayloadAction<string>) => {
            state.form.newPreviewFileName = action.payload;
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.form.title = action.payload;
        },
        setShortDesc: (state, action: PayloadAction<string>) => {
            state.form.shortDesc = action.payload;
        },
        setFullText: (state, action: PayloadAction<string>) => {
            state.form.fullText = action.payload;
        },
    },

    extraReducers(builder) {
        builder
        .addCase(saveArticleThunk.pending, (state, action) => {
            state.formLoadingStatus = LoadingStatus.loading;
        })
        .addCase(saveArticleThunk.fulfilled, (state, action) => {
            state.formLoadingStatus = LoadingStatus.succeeded;

            // if (action.payload === undefined) {
            //     return;
            // }

            // if (action.payload.result === "success") {
            //     const initialForm = getInitialState().form;
            //     state.form.id = initialForm.id;
            //     state.form.previewFileUrl = initialForm.previewFileUrl;
            //     state.form.newPreviewFileName = initialForm.newPreviewFileName;
            //     state.form.category = initialForm.category;
            //     state.form.title = initialForm.title;
            //     state.form.shortDesc = initialForm.shortDesc;
            //     state.form.fullText = initialForm.fullText;
            // }
        })
        .addCase(saveArticleThunk.rejected, (state, action) => {
            state.formLoadingStatus = LoadingStatus.failed;
        })

        .addCase(fetchArticleThunk.pending, (state, action) => {

        })
        .addCase(fetchArticleThunk.fulfilled, (state, action: PayloadAction<JsonResponseInterface<ArticleShortInfoInterface>>) => {
            if (action.payload === undefined) {
                return;
            }

            if (action.payload.result === "success" && action.payload.data !== null) {
                const data = action.payload.data;
                state.form.id = data.id.toString();
                state.form.title = data.title;
                state.form.category = data.category === null ? notSelectedId : data.category.id;
                state.form.previewFileUrl = data.previewUrl;
                state.form.fullText = data.fullText;
                state.form.shortDesc = data.previewText;
            }
        })
        .addCase(fetchArticleThunk.rejected, (state, action) => {

        })

        .addCase(getThreeThunk.pending, (state, action) => {
            state.categoriesLoadingStatus = LoadingStatus.loading;
        })
        .addCase(getThreeThunk.fulfilled, (state, action) => {
            state.categoriesLoadingStatus = LoadingStatus.succeeded;

            if (action.payload === undefined) {
                return;
            }
            if (action.payload.result === "success" && action.payload.data !== null) {
                state.categoriesList = action.payload.data.list;
            }
        })
        .addCase(getThreeThunk.rejected, (state, action) => {
            state.categoriesLoadingStatus = LoadingStatus.failed;
        })
    }
});

export const categorySelector = (state: RootState) => state.inCPEditArticleForm.form.category;
export const titleSelector = (state: RootState) => state.inCPEditArticleForm.form.title;
export const previewFileUrlSelector = (state: RootState) => state.inCPEditArticleForm.form.previewFileUrl;
export const newPreviewFileNameSelector = (state: RootState) => state.inCPEditArticleForm.form.newPreviewFileName;
export const shortDescSelector = (state: RootState) => state.inCPEditArticleForm.form.shortDesc;
export const fullTextSelector = (state: RootState) => state.inCPEditArticleForm.form.fullText;
export const articleIsReadyToSaveSelector = (state: RootState) => {
    return state.inCPEditArticleForm.form.category !== -1 &&
           state.inCPEditArticleForm.form.title.trim() !== "" &&
           state.inCPEditArticleForm.form.previewFileUrl !== "" &&
           state.inCPEditArticleForm.form.shortDesc.trim() !== "" &&
           state.inCPEditArticleForm.form.fullText.trim() !== "";
};
export const formLoadingStatusSelector = (state: RootState) => state.inCPEditArticleForm.formLoadingStatus;
export const categoriesLoadingStatusSelector = (state: RootState) => state.inCPEditArticleForm.categoriesLoadingStatus;
export const categoriesListSelector = (state: RootState) => state.inCPEditArticleForm.categoriesList;
export const validationErrorsSelector = (state: RootState) => state.inCPEditArticleForm.validationErrors;

export const {
    setCategory,
    setTitle,
    setShortDesc,
    setFullText,
    setPreviewFileUrl,
    setNewPreviewFileName,
} = EditArticleFormSlice.actions;

export const EditArticleFormReducer = EditArticleFormSlice.reducer;