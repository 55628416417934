import { Alert } from "@mui/material";
import { ReactNode, createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { userLangSelector } from "@/features/LangSwitcher/Slice/LangSlice";
import { useSelector } from "react-redux";

const ErrorContext = createContext<{ errorId: string }>({ errorId: '' });
const useErrorContext = () => useContext(ErrorContext);

function ErrorFallback() {

    const { t } = useTranslation();
    const { errorId } = useErrorContext();

    return (
        <Alert severity="error">
            <div>
                {t("Something went wrong. Error code:")} {errorId}
            </div>
        </Alert>
    );
}



function ErrorsCatcher({ children, errorId }: { children: ReactNode | ReactNode[], errorId: string, }) {

    const lang = useSelector(userLangSelector);

    function handleOnError({ errorId, error, componentStack }: { errorId: string, error: unknown, componentStack: string | undefined }) {
        Sentry.captureException("Rendering error", {
            extra: {
                errorId,
                error,
                componentStack,
            },
        });
    
        Sentry.showReportDialog({
            lang,
        });
    }
    
    return (
        <ErrorContext.Provider value={{ errorId }}>
            <Sentry.ErrorBoundary
                fallback={ErrorFallback}
                showDialog={true}
                onError={(error, componentStack) => handleOnError({ errorId, error, componentStack })}
            >
                {children}
            </Sentry.ErrorBoundary>
        </ErrorContext.Provider>
    );
}

export default ErrorsCatcher;