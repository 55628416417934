class DocumentsToUpload {
    private files: DocumentToUploadInterface[] = [];

    public getFileById({id}: {id: DocumentToUploadInterface["id"]}) {
        const found = this.files.find(fileInList => fileInList.id === id);
        if (found === undefined) {
            throw new Error(`File with id: ${id} not found.`);
        }
        return found.file;
    }

    public setFile({id, file}: DocumentToUploadInterface) {
        const fileInList = this.files.find(fileInList => fileInList.id === id);
        if (fileInList === undefined) {
            this.files.push({id, file});
            return;
        }
        this.files = this.files.map(fileInList => {
            if (fileInList.id !== id) {
                return fileInList;
            }
            return {
                id: fileInList.id,
                file: file
            };
        });
    }

    public deleteFile({id}: {id: DocumentToUploadInterface["id"]}) {
        this.files = this.files.filter(fileInList => fileInList.id !== id);
    }
}

export const docsToUpload = new DocumentsToUpload();

export interface DocumentToUploadInterface {
    id: string,
    file: File,
}