import {createAsyncThunk} from "@reduxjs/toolkit";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios, {AxiosError} from "axios";
import {showPopup} from "@/features/PopupMessages/PopupMessages";
import {JsonResponseInterface} from "@/common/Http/Interfaces/JsonResponseInterface";
import i18n from "@/i18n/i18n";
import configuredStore from "@/store/Store";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";
import { EditArticleSaveDataInterface } from "./Interfaces/EditArticleSaveDataInterface";

const restApiConfig = new RESTAPIConfig();

export const saveArticleThunk = createAsyncThunk(
    "editArticleForm/save",
    async ({data}: {data: EditArticleSaveDataInterface,}) => {
        const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getSaveKnowledgeBaseEditArticle();
        let response;
        const userLang = configuredStore.getState().lang.userLang;
        const token = getAccessToken(configuredStore.getState());
        try {
            response = await axios.post<JsonResponseInterface<null>>(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    [userLangHeaderName]: userLang,
                    "Authorization": `Bearer ${token}`,
                }
            });

            return response.data;

        } catch (e: unknown) {
            const error = e as AxiosError;
            //@ts-ignore
            const responseStatus = error.response.status;
            //@ts-ignore
            const commonErrorsText = error.response.data.errors.toString().trim();
            const errorText = commonErrorsText !== "" ? commonErrorsText : "";
            const message = i18n.t("Error with code and text", {code: responseStatus, error: errorText});
            showPopup({
                type: "error",
                message,
                autoClose: 5000,
            });

            //@ts-ignore
            return e.response.data;
        }
    },
)