import { RootState } from "@/store/Store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

function getInitialState() {
    return {
        error: "",
    }
}

export const ShowGlobalErrorSlice = createSlice({
    name: "ShowGlobalErrorSlice",
    initialState: getInitialState(),
    reducers: {
        setGlobalError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
    }
});

export const {
    setGlobalError,
} = ShowGlobalErrorSlice.actions;

export const globalErrorSelector = (state: RootState) => state.globalError.error;

export const ShowGlobalErrorReducer = ShowGlobalErrorSlice.reducer;