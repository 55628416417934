import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import axios from "axios";
import {LoadingStatus} from "@/store/LoadingStatus";
import configuredStore from "@/store/Store";
import {getAccessToken, userLangHeaderName} from "@/store/ApiSlice";

const restApiConfig = new RESTAPIConfig();

export const logoutThunk = createAsyncThunk("logout/logout", async() => {
    const url = restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.logout();
    let response;

    const token = getAccessToken(configuredStore.getState());
    const userLang = configuredStore.getState().lang.userLang;

    try {
        response = await axios.post(url, {}, {
            headers: {
                [userLangHeaderName]: userLang,
                "Authorization": `Bearer ${token}`,
            }
        });
    } catch (e) {
        console.log(e);
    }

    //@ts-ignore
    return response.data;
});

export const LogoutSlice = createSlice({
    name: "LogoutSlice",
    initialState: {
        status: "idle" as LoadingStatus,
    },
    reducers: {},
    extraReducers(builder) {
        builder
        .addCase(logoutThunk.pending, (state, action) => {
            state.status = LoadingStatus.loading;
        })
        .addCase(logoutThunk.fulfilled, (state, action) => {
            state.status = LoadingStatus.succeeded;
        })
        .addCase(logoutThunk.rejected, (state, action) => {
            state.status = LoadingStatus.failed;
        })
    }
});

//@ts-ignore
export const logoutStatusSelector = state => state.logout.status;

export const LogoutReducer = LogoutSlice.reducer;