import i18n from "@/i18n/i18n";
const intersect = require('intersect');

export const appRoles = {
    supremeAdmin: {alias: "supremeAdmin", title: i18n.t("Supreme administrator")},
    admin: {alias: "admin", title: i18n.t("Administrator")},
    partner: {alias: "partner", title: i18n.t("Partner")},
    manager: {alias: "manager", title: i18n.t("Manager")},
    seniorManager: {alias: "seniorManager", title: i18n.t("Senior manager")},
    notAuthorizedUser: {alias: "notAuthorizedUser", title: i18n.t("Unauthorized user")},
    accountant: {alias: "accountant", title: i18n.t("Accountant")},
    contentManager: {alias: "contentManager", title: i18n.t("Content manager")},
}

//TODO translate

export function doesUserRolesMatchRequired({suitableRoles, userRoles}: {suitableRoles: string[], userRoles: string[] | undefined}) {
    if (userRoles === undefined) {
        return false;
    }
    return intersect(suitableRoles, userRoles).length > 0;
}
